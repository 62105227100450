import { MarkdownDisplay } from '@kanda-libs/ks-component-ts';
import { Text } from '@kanda-libs/ks-design-library';
import type { JobItem } from '@kanda-libs/ks-frontend-services';
import LeadItemsTotals from '../../LeadItemsTotals';
import useLeadSingleItem from './useLeadSingleItem';

export interface LeadSingleItemProps {
  item: JobItem;
  isLoading: boolean;
}

const LeadSingleItem: React.FunctionComponent<LeadSingleItemProps> = function ({
  item,
  isLoading,
}) {
  const { title, description, totals } = useLeadSingleItem(item);
  return (
    <>
      <div className="flex flex-col">
        <Text
          text="Job Title"
          isLoading={isLoading}
          className="text-14-22-em text-neutral-700 mb-2"
        />
        <Text
          text={title}
          isLoading={isLoading}
          className="text-14-22 text-neutral-900"
        />
      </div>
      {description && (
        <div className="flex flex-col">
          <Text
            text="Job Description"
            isLoading={isLoading}
            className="text-14-22-em text-neutral-700"
          />
          <Text
            className="text-14-22"
            isLoading={isLoading}
            text={<MarkdownDisplay>{description}</MarkdownDisplay>}
            skeletonProps={{
              width: 180,
              count: 3,
            }}
          />
        </div>
      )}
      <div className="md:ml-auto -mt-4">
        <LeadItemsTotals totals={totals} isLoading={isLoading} />
      </div>
    </>
  );
};

export default LeadSingleItem;

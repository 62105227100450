import type { EnquiryExtendedState } from 'pages/Leads/helpers';

export const getSuffix = (status: EnquiryExtendedState): string => {
  switch (status) {
    case 'viewed':
    case 'contact_only':
      return 'Viewed';
    case 'contact_agreed':
    case 'preferences_set':
    case 'ask_for_quote':
    case 'submitted':
      return 'Received';
    case 'quoted':
      return 'Quoted';
    case 'converted_to_job':
      return 'Converted';
    case 'finance_requested':
      return 'Job Uploaded';
    case 'not_interested':
    case 'unreachable':
    case 'other_reason':
      return 'Rejected';
    default:
      return '';
  }
};

import { Helmet } from 'components';
import { useMemo } from 'react';
import { useLeadContext } from './context';

const LeadHelmet: React.FunctionComponent = function ({}) {
  const { isLoading, lead } = useLeadContext();
  const title = useMemo(
    () => (lead?.enquiry_info?.quote ? 'Quote Details' : 'Lead Details'),
    [lead],
  );
  if (isLoading) return null;
  return <Helmet title={title} />;
};

export default LeadHelmet;

import { useLeadContext } from 'pages/Lead/context';
import { useMemo } from 'react';

export type ActionButtonDisplay =
  | undefined
  | 'unarchive'
  | 'convert'
  | 'confirm_details'
  | 'go_to_job';

export interface ActionButtonsHook {
  display: ActionButtonDisplay;
}

export default function useActionButtons(): ActionButtonsHook {
  const { archived, converted, hasQuote, isLoading } = useLeadContext();

  const display: ActionButtonDisplay = useMemo(() => {
    if (isLoading) return undefined;
    if (archived) return 'unarchive';
    if (converted) return 'go_to_job';
    if (hasQuote) return 'confirm_details';
    return 'convert';
  }, [archived, hasQuote, converted, isLoading]);

  return { display };
}

export const NAME_VALIDATION = {
  required: {
    value: true,
    message: 'URL slug is required',
  },
  pattern: {
    value: /^((?!kanda).)*$/gim,
    message: "URL slug cannot contain the word 'Kanda'",
  },
};

import { ErrorBoundary } from '@highlight-run/react';
import { AmplitudeProvider } from '@kanda-libs/ks-amplitude-provider';
import {
  actions,
  Company,
  createAction,
  createAppDispatchHook,
  createRoutedApp,
} from '@kanda-libs/ks-frontend-services';
import type { HandlerPayload } from '@kanda-libs/ks-frontend-services/src/store/helpers';
import { getCreditId } from 'common/job/helpers';
import { URLS, type PageKeys } from 'config';
import type { FunctionComponent } from 'react';
import { Redirect } from 'react-router-dom';
import { store, type AppDispatch, type RootState } from 'store';

import AppWrapper from 'components/AppWrapper';
import CombinedStateWrapper from 'components/CombinedStateWrapper';
import StaffImitationModal from 'components/DesktopHeader/components/StaffImitationModal';
import KandaErrorBoundary from 'components/ErrorBoundary';
import NavMenu from 'components/NavMenu';
import useFacebookPixel from 'hooks/useFacebookPixel';

import AccountInReview from 'pages/AccountInReview';
import AddWorkTypes from 'pages/AddWorkTypes';
import Advertising from 'pages/Advertising';
import AuthLink from 'pages/AuthLink';
import BillingSuccess from 'pages/BillingSuccess';
import ClaimAccount from 'pages/ClaimAccount';
import ClaimResent from 'pages/ClaimResent';
import CreateJob from 'pages/CreateJob';
import CustomCode from 'pages/CustomCode';
import EnterpriseRates from 'pages/EnterpriseRates';
import EnterpriseRatesSuccess from 'pages/EnterpriseRates/Success';
import ExampleJob from 'pages/ExampleJob';
import ForgotPassword from 'pages/ForgotPassword';
import Home from 'pages/Home';
import Identify from 'pages/Identify';
import IdentifySuccess from 'pages/IdentifySuccess';
import Invite from 'pages/Invite';
import JobDetails from 'pages/JobDetails';
import Jobs from 'pages/Jobs';
import Lead from 'pages/Lead';
import LeadAccept from 'pages/LeadAccept';
import Leads from 'pages/Leads';
import Login from 'pages/Login';
import Logout from 'pages/Logout';
import MarketingPackage from 'pages/MarketingPackage';
import MyAccount from 'pages/MyAccount';
import AccountSettings from 'pages/MyAccount/AccountSettings';
import ChangeEmail from 'pages/MyAccount/AccountSettings/ChangeEmail';
import ChangePassword from 'pages/MyAccount/AccountSettings/ChangePassword';
import CompanyInformation from 'pages/MyAccount/CompanyInformation';
import DirectorVerification from 'pages/MyAccount/DirectorVerification';
import MyAccountFinanceEverywhereLink from 'pages/MyAccount/FinanceEverywhereLink';
import FinanceRates from 'pages/MyAccount/FinanceRates';
import JobSettings from 'pages/MyAccount/JobSettings';
import NotificationPreferences from 'pages/MyAccount/NotificationPreferences';
import Subscription from 'pages/MyAccount/Subscription';
import TeamSettings from 'pages/MyAccount/TeamSettings';
import Onboarding from 'pages/Onboarding';
import ReferralsForm from 'pages/Referrals/Form';
import Referrals from 'pages/Referrals/Root';
import ReferralSignUp from 'pages/ReferralSignUp';
import ResetPassword from 'pages/ResetPassword';
import SetupAccount from 'pages/Setup/Account';
import SetupInfo from 'pages/Setup/Info';
import SetupRates from 'pages/Setup/Rates';
import SetupSubscription from 'pages/Setup/Subscription';
import SetupCompany from 'pages/SetupCompany';
import SignUp from 'pages/SignUp';
import SSO from 'pages/SSO';
import Staff from 'pages/Staff';
import StaffLogin from 'pages/StaffLogin';
import SubscriptionReturn from 'pages/SubscriptionReturn';
import SubscriptionReturnSuccess from 'pages/SubscriptionReturn/Success';
import UpdateYourWebsite from 'pages/UpdateYourWebsite';
import Veriff from 'pages/Veriff';
import VerifyEmail from 'pages/VerifyEmail';
import { checkJobIsCustom } from 'utils/Job-functions';
import AnalyticsWrapper from '../AnalyticsWrapper';

const DEFAUL_LOADING_DEPENDENCIES: (keyof RootState)[] = [
  'authUser',
  'company',
  'infoOnboarding',
  'job',
  'enquiry',
  'rate',
  'infoWorkType',
];

const DEFAULT_INITIAL_ACTIONS = [
  createAction(actions.me),
  createAction(actions.getCompanies, {}, false, (data) => {
    const company = data.data[0];
    const workTypes = company?.company_info?.work_types;
    if (!company || !workTypes || workTypes.length === 0)
      return [createAction(actions.infoWorkType, { forceReload: true })];
    return [
      createAction(actions.infoWorkType, {
        params: { work_type: workTypes.join(',') },
        forceReload: true,
      }),
    ];
  }),
  createAction(actions.infoOnboarding),
  createAction(actions.getJobs),
  createAction(actions.getEnquiries, {
    params: {
      q: 'current_state:contact_agreed|contact_only|ask_for_quote|quoted|preferences_set|submitted|converted_to_job',
    },
  }),
  createAction(actions.infoRate),
  createAction(actions.getTags),
];

const {
  pages,
  router: { Router },
} = createRoutedApp<RootState, PageKeys>(
  store,
  {
    home: {
      path: URLS.home,
      PageComponent: Home,
      requiredRoles: ['*'],
      loadingDependencies: [
        ...DEFAUL_LOADING_DEPENDENCIES,
        'infoStats',
        'infoSearch',
      ],
      initialDataActions: [
        ...DEFAULT_INITIAL_ACTIONS,
        createAction(actions.infoStats),
        createAction(actions.infoSearch, {
          body: {
            index: ['job'],
            q: '',
          },
        }),
      ],
    },
    leads: {
      path: URLS.leads,
      PageComponent: Leads,
      requiredRoles: ['*'],
      loadingDependencies: [
        ...DEFAUL_LOADING_DEPENDENCIES,
        'infoStats',
        'infoSearch',
      ],
      initialDataActions: [
        ...DEFAULT_INITIAL_ACTIONS,
        createAction(actions.infoStats),
        createAction(actions.infoSearch, {
          body: {
            index: ['job'],
            q: '',
          },
        }),
      ],
    },
    jobs: {
      path: URLS.jobs,
      PageComponent: Jobs,
      requiredRoles: ['*'],
      loadingDependencies: [
        ...DEFAUL_LOADING_DEPENDENCIES,
        'infoStats',
        'infoSearch',
      ],
      initialDataActions: [
        ...DEFAULT_INITIAL_ACTIONS,
        createAction(actions.infoStats),
        createAction(actions.infoSearch, {
          body: {
            index: ['job'],
            q: '',
          },
        }),
      ],
    },
    lead: {
      path: URLS.lead,
      PageComponent: Lead,
      idRequired: true,
      requiredRoles: ['*'],
      initialDataActions: [
        ...DEFAULT_INITIAL_ACTIONS,
        createAction(actions.getEnquiry, {}, true),
      ],
      loadingDependencies: [...DEFAUL_LOADING_DEPENDENCIES, 'enquiry'],
    },
    job: {
      path: URLS.job,
      PageComponent: JobDetails,
      idRequired: true,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'job', 'credit', 'infoWorkType'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.checkJob, { useDevHeader: true }, true),
        createAction(
          actions.getJob,
          {
            forceReload: true,
          },
          true,
          (data) => {
            const job = data.data;
            const workType = job.work_type;
            const financeStatus = job?.finance_status;
            return [
              ...(workType
                ? [
                    createAction(actions.infoWorkType, {
                      params: {
                        work_type: workType,
                      },
                    }),
                  ]
                : []),
              ...(financeStatus !== 'not_applied'
                ? [
                    createAction(actions.getCredit, {
                      params: {
                        id: getCreditId(job),
                      },
                    }),
                  ]
                : []),
            ];
          },
        ),
      ],
    },
    createJob: {
      path: URLS.createJob,
      PageComponent: CreateJob,
      requiredRoles: ['*'],
      loadingDependencies: [...DEFAUL_LOADING_DEPENDENCIES],
      initialDataActions: [...DEFAULT_INITIAL_ACTIONS],
    },
    createJobFromDraft: {
      path: URLS.createJobFromDraft,
      PageComponent: CreateJob,
      idRequired: true,
      requiredRoles: ['*'],
      loadingDependencies: [...DEFAUL_LOADING_DEPENDENCIES],
      initialDataActions: [
        ...DEFAULT_INITIAL_ACTIONS,
        createAction(
          actions.getJob,
          {
            forceReload: true,
          },
          true,
          (data) => {
            const job = data.data;
            const fetchDocuments = checkJobIsCustom(job);
            if (!fetchDocuments) return [];
            const ids = job.job_items
              .map((item) => item.quote_document?.id)
              .filter((val) => val !== undefined);
            if (ids.length === 0) return [];
            return [
              createAction(actions.getDocuments, {
                params: {
                  q: `id:${ids.join('|')}`,
                },
                forceReload: true,
              }),
            ];
          },
        ),
      ],
    },
    createExampleJob: {
      path: URLS.createExampleJob,
      PageComponent: CreateJob,
      idRequired: true,
      requiredRoles: ['*'],
      loadingDependencies: [...DEFAUL_LOADING_DEPENDENCIES],
      initialDataActions: [
        createAction(actions.me),
        createAction(actions.getCompanies, {}, false),
        createAction(actions.infoWorkType, {
          params: { work_type: 'other' },
          forceReload: true,
        }),
        createAction(actions.infoOnboarding),
        createAction(actions.getJobs),
        createAction(actions.infoRate),
        createAction(
          actions.getJob,
          {
            forceReload: true,
          },
          true,
          (data) => {
            const job = data.data;
            const fetchDocuments = checkJobIsCustom(job);
            if (!fetchDocuments) return [];
            const ids = job.job_items
              .map((item) => item.quote_document?.id)
              .filter((val) => val !== undefined);
            if (ids.length === 0) return [];
            return [
              createAction(actions.getDocuments, {
                params: {
                  q: `id:${ids.join('|')}`,
                },
                forceReload: true,
              }),
            ];
          },
        ),
      ],
    },
    createExampleJobInvalid: {
      path: URLS.createExampleJobInvalid,
      PageComponent: () => <Redirect to={URLS.jobs} />,
      requiredRoles: ['*'],
      loadingDependencies: [],
      initialDataActions: [],
    },
    login: {
      path: URLS.login,
      PageComponent: Login,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    loginSso: {
      path: URLS.loginSso,
      PageComponent: SSO,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    staff: {
      path: URLS.staff,
      PageComponent: Staff,
      requiredRoles: ['staff'],
      loadingDependencies: [],
      initialDataActions: [],
    },
    staffLogin: {
      path: URLS.staffLogin,
      PageComponent: StaffLogin,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    logout: {
      path: URLS.logout,
      PageComponent: Logout,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    forgotPassword: {
      path: URLS.forgotPassword,
      PageComponent: ForgotPassword.Request,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    forgotPasswordConfirmation: {
      path: URLS.forgotPasswordConfirmation,
      PageComponent: ForgotPassword.Confirmation,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    resetPassword: {
      path: URLS.resetPassword,
      PageComponent: ResetPassword.Landing,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    resetPasswordConfirmation: {
      path: URLS.resetPasswordConfirmation,
      PageComponent: ResetPassword.Confirmation,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    archive: {
      path: URLS.archive,
      PageComponent: Jobs,
      requiredRoles: ['*'],
      loadingDependencies: [
        ...DEFAUL_LOADING_DEPENDENCIES,
        'infoStats',
        'infoSearch',
      ],
      initialDataActions: [
        ...DEFAULT_INITIAL_ACTIONS,
        createAction(actions.infoStats),
        createAction(actions.infoSearch, {
          body: {
            index: ['job'],
            q: '',
          },
        }),
      ],
    },
    leadsArchive: {
      path: URLS.leadsArchive,
      PageComponent: Leads,
      requiredRoles: ['*'],
      loadingDependencies: [
        ...DEFAUL_LOADING_DEPENDENCIES,
        'infoStats',
        'infoSearch',
      ],
      initialDataActions: [
        ...DEFAULT_INITIAL_ACTIONS,
        createAction(actions.infoStats),
        createAction(actions.infoSearch, {
          body: {
            index: ['job'],
            q: '',
          },
        }),
      ],
    },
    authLink: {
      path: URLS.authLink,
      PageComponent: AuthLink,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    myAccount: {
      path: URLS.myAccount,
      PageComponent: MyAccount,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    directorVerification: {
      path: URLS.directorVerification,
      PageComponent: DirectorVerification,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    companyInformation: {
      path: URLS.companyInformation,
      PageComponent: CompanyInformation,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    financeRates: {
      path: URLS.financeRates,
      PageComponent: FinanceRates,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'rate', 'infoOnboarding'],
      initialDataActions: [
        createAction(
          actions.getCompanies,
          undefined,
          false,
          (data: HandlerPayload<Company[]>) => {
            const company = data?.data?.[0];
            const id = company?.cid;
            if (!id) return [];
            return [
              createAction(actions.getEnrolment, {
                params: {
                  id,
                },
              }),
            ];
          },
        ),
        createAction(actions.infoRate),
        createAction(actions.infoOnboarding),
        createAction(actions.infoWorkType, {
          params: {
            ignore_rate_filter: 'true',
          },
          forceReload: true,
        }),
      ],
    },
    jobSettings: {
      path: URLS.jobSettings,
      PageComponent: JobSettings,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'rate', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoRate),
        createAction(actions.infoOnboarding),
      ],
    },
    financeEverywhereLink: {
      path: URLS.financeEverywhereLink,
      PageComponent: MyAccountFinanceEverywhereLink,
      requiredRoles: ['*'],
      loadingDependencies: ['company'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.getTags),
        createAction(actions.infoOnboarding),
      ],
    },
    accountSettings: {
      path: URLS.accountSettings,
      PageComponent: AccountSettings,
      requiredRoles: ['*'],
      loadingDependencies: ['company'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    teamSettings: {
      path: URLS.teamSettings,
      PageComponent: TeamSettings,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    changeEmail: {
      path: URLS.changeEmail,
      PageComponent: ChangeEmail,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    changePassword: {
      path: URLS.changePassword,
      PageComponent: ChangePassword,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    billingSuccess: {
      path: URLS.billingSuccess,
      PageComponent: BillingSuccess,
      requiredRoles: ['*'],
      loadingDependencies: ['company'],
      initialDataActions: [createAction(actions.getCompanies)],
    },
    subscription: {
      path: URLS.subscription,
      PageComponent: Subscription,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'subscription', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.getSubscriptions),
        createAction(actions.infoOnboarding),
      ],
    },
    subscriptionReturn: {
      path: URLS.subscriptionReturn,
      PageComponent: SubscriptionReturn,
      requiredRoles: ['*'],
      loadingDependencies: ['company'],
      initialDataActions: [createAction(actions.getCompanies)],
    },
    subscriptionSuccess: {
      path: URLS.subscriptionSuccess,
      PageComponent: SubscriptionReturnSuccess,
      requiredRoles: ['*'],
      loadingDependencies: ['company'],
      initialDataActions: [createAction(actions.getCompanies)],
    },
    notificationPreferences: {
      path: URLS.notificationPreferences,
      PageComponent: NotificationPreferences,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    claimAccount: {
      path: URLS.claimAccount,
      PageComponent: ClaimAccount,
      requiredRoles: [],
      loadingDependencies: ['authUser', 'company'],
      initialDataActions: [
        createAction(actions.me),
        createAction(actions.getCompanies),
      ],
    },
    claimAccountResent: {
      path: URLS.claimAccountResent,
      PageComponent: ClaimResent,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    exampleJob: {
      path: URLS.exampleJob,
      PageComponent: ExampleJob,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    leadAccept: {
      path: URLS.leadAccept,
      PageComponent: LeadAccept,
      idRequired: true,
      requiredRoles: ['*'],
      loadingDependencies: ['lead', 'company'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.getLead, {}, true),
      ],
    },
    signUp: {
      path: URLS.signUp,
      PageComponent: SignUp,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    referrals: {
      path: URLS.referrals,
      PageComponent: Referrals,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'getCompanyReferrals'],
      initialDataActions: [
        createAction(actions.getCompanies, {}, false, (data) => {
          const company = data.data[0];
          const id = company?.id;
          if (!id) return [];
          return [
            createAction(actions.getCompanyReferrals, {
              params: {
                id,
              },
              forceReload: true,
            }),
          ];
        }),
      ],
    },
    referralsForm: {
      path: URLS.referralsForm,
      PageComponent: ReferralsForm,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'getCompanyReferrals'],
      initialDataActions: [
        createAction(actions.getCompanies, {}, false, (data) => {
          const company = data.data[0];
          if (!company || !company.id) return [];
          return [
            createAction(actions.getCompanyReferrals, {
              params: {
                id: store.getState().auth.user?.cid || '',
              },
              forceReload: true,
            }),
          ];
        }),
      ],
    },
    referralSignUp: {
      path: URLS.referralSignUp,
      PageComponent: ReferralSignUp,
      idRequired: true,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    identify: {
      path: URLS.identify,
      PageComponent: Identify,
      idRequired: true,
      requiredRoles: [],
      loadingDependencies: ['company', 'getCompanyDirectorVerification'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.getCompanyDirectorVerification, {}, true),
      ],
    },
    identifySuccess: {
      path: URLS.identifySuccess,
      PageComponent: IdentifySuccess,
      idRequired: true,
      requiredRoles: [],
      loadingDependencies: [],
      initialDataActions: [],
    },
    setupCompany: {
      path: URLS.setupCompany,
      PageComponent: SetupCompany,
      requiredRoles: ['*'],
      loadingDependencies: ['company'],
      initialDataActions: [createAction(actions.getCompanies)],
    },
    setupPartnerCompany: {
      path: URLS.setupPartnerCompany,
      PageComponent: SetupCompany,
      requiredRoles: ['*'],
      loadingDependencies: ['company'],
      initialDataActions: [createAction(actions.getCompanies)],
    },
    verifyEmail: {
      path: URLS.verifyEmail,
      PageComponent: VerifyEmail.Landing,
      requiredRoles: ['*'],
      loadingDependencies: ['company'],
      initialDataActions: [createAction(actions.getCompanies)],
    },
    verifyEmailSuccess: {
      path: URLS.verifyEmailSuccess,
      PageComponent: VerifyEmail.Confirmation,
      requiredRoles: ['*'],
      loadingDependencies: ['company'],
      initialDataActions: [createAction(actions.getCompanies)],
    },
    veriff: {
      path: URLS.veriff,
      PageComponent: Veriff,
      requiredRoles: ['*'],
      loadingDependencies: ['getCompanyDirectorVerification', 'company'],
      initialDataActions: [
        createAction(actions.getCompanyDirectorVerification),
        createAction(actions.getCompanies),
      ],
    },
    onboarding: {
      path: URLS.onboarding,
      PageComponent: Onboarding,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding', 'document'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
        createAction(actions.infoRate),
        createAction(actions.getDocuments),
      ],
    },
    onboardingSteps: {
      path: URLS.onboardingSteps,
      PageComponent: Onboarding,
      idRequired: true,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding', 'document'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
        createAction(actions.getDocuments),
      ],
    },
    customCode: {
      path: URLS.customCode,
      PageComponent: CustomCode,
      loadingDependencies: [],
      initialDataActions: [],
    },
    updateYourWebsiteRedirect: {
      path: URLS.updateYourWebsiteRedirect,
      PageComponent: UpdateYourWebsite,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    updateYourWebsite: {
      path: URLS.updateYourWebsite,
      PageComponent: UpdateYourWebsite,
      idRequired: true,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    developerRedirect: {
      path: URLS.developerRedirect,
      PageComponent: UpdateYourWebsite,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    developer: {
      path: URLS.developer,
      PageComponent: UpdateYourWebsite,
      idRequired: true,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    advertisingRedirect: {
      path: URLS.advertisingRedirect,
      PageComponent: Advertising,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    advertising: {
      path: URLS.advertising,
      PageComponent: Advertising,
      idRequired: true,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    invite: {
      path: URLS.invite,
      PageComponent: Invite.Welcome,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    inviteForm: {
      path: URLS.inviteForm,
      PageComponent: Invite.Form,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    inviteSummary: {
      path: URLS.inviteSummary,
      PageComponent: Invite.Summary,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'infoOnboarding'],
      initialDataActions: [
        createAction(actions.getCompanies),
        createAction(actions.infoOnboarding),
      ],
    },
    setupAccount: {
      path: URLS.setupAccount,
      PageComponent: SetupAccount,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'authUser'],
      initialDataActions: [
        createAction(actions.me),
        createAction(actions.getCompanies),
      ],
    },
    setupRates: {
      path: URLS.setupRates,
      PageComponent: SetupRates,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'authUser'],
      initialDataActions: [
        createAction(actions.me),
        createAction(actions.getCompanies),
      ],
    },
    setupInfo: {
      path: URLS.setupInfo,
      PageComponent: SetupInfo,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'authUser'],
      initialDataActions: [
        createAction(actions.me),
        createAction(actions.getCompanies),
      ],
    },
    setupSubscription: {
      path: URLS.setupSubscription,
      PageComponent: SetupSubscription,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'authUser', 'document', 'infoGetCache'],
      initialDataActions: [
        createAction(actions.me),
        createAction(actions.infoGetCache),
        createAction(actions.getCompanies),
        createAction(actions.getDocuments),
        //   createAction(
        //     actions.getCompanies,
        //     {},
        //     false,
        //     (companies: Company[]) => {
        //       if (companies.length === 0) return [];
        //       const cid = companies[0]?.cid;
        //       if (!cid) return [];
        //       const name = `UNSIGNED-PREMIUMCREDIT_Kanda-T&C-${cid}.pdf`;
        //       return [
        //         createAction(actions.getDocuments, {
        //           params: { q: `name:${name}` },
        //         }),
        //       ];
        //     },
        //   ),
      ],
    },
    accountInReview: {
      path: URLS.accountInReview,
      PageComponent: AccountInReview,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'authUser'],
      initialDataActions: [
        createAction(actions.me),
        createAction(actions.getCompanies),
      ],
    },
    enterpriseRates: {
      path: URLS.enterpriseRates,
      PageComponent: EnterpriseRates,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'authUser', 'rate'],
      initialDataActions: [
        createAction(actions.me),
        createAction(actions.getCompanies),
        createAction(actions.infoRate, {
          params: {
            cid: 'enterprise',
          },
        }),
      ],
    },
    enterpriseRatesSuccess: {
      path: URLS.enterpriseRatesSuccess,
      PageComponent: EnterpriseRatesSuccess,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'authUser', 'rate'],
      initialDataActions: [
        createAction(actions.me, {
          forceReload: true,
        }),
        createAction(actions.getCompanies, {
          forceReload: true,
        }),
        createAction(actions.infoRate, {
          params: {
            cid: 'enterprise',
          },
          forceReload: true,
        }),
      ],
    },
    marketingPackage: {
      path: URLS.marketingPackage,
      PageComponent: MarketingPackage,
      requiredRoles: ['*'],
      loadingDependencies: ['company', 'authUser'],
      initialDataActions: [
        createAction(actions.me),
        createAction(actions.getCompanies),
      ],
    },

    addWorkTypes: {
      path: URLS.addWorkTypes,
      PageComponent: AddWorkTypes,
      requiredRoles: ['*'],
      loadingDependencies: [
        ...DEFAUL_LOADING_DEPENDENCIES,
        'infoStats',
        'infoSearch',
      ],
      initialDataActions: [
        ...DEFAULT_INITIAL_ACTIONS,
        createAction(actions.infoStats),
        createAction(actions.infoSearch, {
          body: {
            index: ['job'],
            q: '',
          },
        }),
      ],
    },
  },
  undefined,
  CombinedStateWrapper,
);

const useAppDispatch = createAppDispatchHook<RootState, AppDispatch>();

export { pages, useAppDispatch };

const App: FunctionComponent = function () {
  useFacebookPixel();
  return (
    <AmplitudeProvider>
      <AnalyticsWrapper>
        <ErrorBoundary>
          <KandaErrorBoundary>
            <AppWrapper>
              <>
                <Router>
                  <NavMenu />
                </Router>
                <StaffImitationModal />
              </>
            </AppWrapper>
          </KandaErrorBoundary>
        </ErrorBoundary>
      </AnalyticsWrapper>
    </AmplitudeProvider>
  );
};

export default App;

import { Field, Form, FormTheme } from '@kanda-libs/ks-component-ts';
import { SHOW_CONVERTED } from './constants';
import useLeadsFilters from './useLeadsFilters';

const LeadsFilters: React.FunctionComponent = function ({}) {
  const { form, sourceOptions, isLoading } = useLeadsFilters();
  if (isLoading) return null;
  return (
    <FormTheme variant="streamline">
      <Form
        form={form}
        onSubmit={() => {}}
        id="leads-filters"
        className="flex flex-col md:flex-row gap-x-4 md:justify-end"
      >
        <div className="md:w-60">
          <Field.Select
            options={SHOW_CONVERTED}
            name="converted"
            label="Show converted leads?"
          />
        </div>
        {sourceOptions && (
          <div className="md:w-60">
            <Field.Select
              options={sourceOptions}
              name="source"
              label="Redirect Source"
            />
          </div>
        )}
      </Form>
    </FormTheme>
  );
};

export default LeadsFilters;

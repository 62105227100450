import { useToast } from '@kanda-libs/ks-design-library';
import { actions, Enquiry, Job } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { URLS } from 'config';
import useApiError from 'hooks/useApiError';
import { useLeadContext } from 'pages/Lead/context';
import { useCallback } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { scrollToTop } from 'utils';

export interface LeadDetailsConfirmDetailsHook {
  onClick: () => void;
  isSubmitting: boolean;
}

export const getJobTitle = (lead: Enquiry): string => {
  const itemTitle = lead?.enquiry_info?.job_info?.job_items?.[0]?.title;
  if (itemTitle) return itemTitle;
  const quote = lead?.enquiry_info?.quote;
  if (quote) return ['Job from Quote:', quote.name].join(' ');
  const consumer = lead?.enquiry_info?.consumer;
  if (consumer)
    return ['Job for', consumer.first_name, consumer.last_name].join(' ');
  return 'Job from enquiry';
};

export default function useLeadDetailsConfirmDetails(
  handleClose: () => void,
): LeadDetailsConfirmDetailsHook {
  const { id, lead, isSubmitting, setIsSubmitting } = useLeadContext();
  const dispatch = useAppDispatch();
  const { showSuccess } = useToast();
  const { push } = useHistory();
  const onConvertError = useApiError('Unknown error converting lead to job');
  const onFetchError = useApiError('Unknown error fetching job');
  const onPutError = useApiError('Unknown error updating job');
  const onSendError = useApiError('Unknown error sending job');

  const onClick = useCallback(() => {
    if (!id || !lead) return;
    setIsSubmitting(true);
    dispatch(
      actions.postEnquiryConvertJob({
        params: { id },
        onError: (err) => {
          onConvertError(err);
          handleClose();
          setIsSubmitting(false);
        },
        onSuccess: () => {
          dispatch(
            actions.getJob({
              params: { id },
              onError: (err) => {
                onFetchError(err);
                push(generatePath(URLS.createJobFromDraft, { id }));
                handleClose();
                setIsSubmitting(false);
              },
              onSuccess: (draft: Job) => {
                const body: Job = {
                  ...draft,
                  title: getJobTitle(lead),
                };
                dispatch(
                  actions.putJob({
                    params: { id },
                    body,
                    onError: (err) => {
                      onPutError(err);
                      push(generatePath(URLS.createJobFromDraft, { id }));
                      handleClose();
                      setIsSubmitting(false);
                    },
                    onSuccess: (job: Job) => {
                      dispatch(
                        actions.sendJob({
                          params: { id: job.id as string },
                          onError: (err) => {
                            scrollToTop();
                            onSendError(err);
                            push(generatePath(URLS.createJobFromDraft, { id }));
                            handleClose();
                            setIsSubmitting(false);
                          },
                          onSuccess: () => {
                            scrollToTop();
                            showSuccess('Job sent');
                            push(generatePath(URLS.job, { id }));
                            handleClose();
                            setIsSubmitting(false);
                          },
                        }),
                      );
                    },
                  }),
                );
              },
            }),
          );
        },
      }),
    );
  }, [
    id,
    lead,
    dispatch,
    showSuccess,
    push,
    onConvertError,
    onFetchError,
    onPutError,
    onSendError,
    setIsSubmitting,
    handleClose,
  ]);

  return {
    onClick,
    isSubmitting,
  };
}

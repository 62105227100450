import { Button, type ButtonTextProps } from '@kanda-libs/ks-design-library';
import useLeadEditDetails from './useLeadEditDetails';

export interface LeadEditDetailsProps {
  size?: ButtonTextProps['size'];
  className?: string;
}

const LeadEditDetails: React.FunctionComponent<LeadEditDetailsProps> =
  function ({ size = 48, className = 'max-w-max' }) {
    const { onClick, isSubmitting } = useLeadEditDetails();
    return (
      <Button.Text
        id="lead-edit-details"
        size={size}
        onClick={onClick}
        disabled={isSubmitting}
        icon="edit"
        label="Edit"
        className={className}
        variant="outline"
      />
    );
  };

export default LeadEditDetails;

import {
  useForm,
  type SelectOption,
  type UseFormReturn,
} from '@kanda-libs/ks-component-ts';
import { useAppDispatch } from 'components/App';
import { useLeadsContext } from 'pages/Leads/context';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  getLeadsConvertedFilter,
  getLeadsSourceFilter,
} from 'store/selectors/filters';
import { setFilter } from 'store/slices/filters';
import { getLeadsSourcesOptions } from './helpers';

export type YesNo = 'yes' | 'no';

export interface LeadsFiltersFormValues {
  source: string;
  converted: YesNo;
}

export interface LeadsFiltersFormHook {
  form: UseFormReturn<LeadsFiltersFormValues>;
  sourceOptions: SelectOption[] | undefined;
  isLoading: boolean;
}

export default function useLeadsFiltersForm(): LeadsFiltersFormHook {
  const { allLeads, isLoading } = useLeadsContext();
  const { search } = useLocation();

  const dispatch = useAppDispatch();

  const sourceFilter = useSelector(getLeadsSourceFilter);
  const includeConvertedFilter = useSelector(getLeadsConvertedFilter);

  const form = useForm<LeadsFiltersFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      source: sourceFilter || 'all',
      converted: includeConvertedFilter ? 'yes' : 'no',
    },
  });

  const { watch, setValue } = form;

  const sourceSelection = watch('source');
  const includeConvertedSelection = watch('converted');

  const sourceOptions = useMemo(() => {
    if (isLoading || allLeads.length === 0) return undefined;
    return getLeadsSourcesOptions(allLeads);
  }, [isLoading, allLeads]);

  useEffect(() => {
    dispatch(
      setFilter({
        leads: {
          source: sourceSelection,
          includeConverted: includeConvertedSelection === 'yes',
        },
      }),
    );
  }, [dispatch, sourceSelection, includeConvertedSelection]);

  useEffect(() => {
    if (!search || search === '') return;
    const params = new URLSearchParams(search);
    const showConverted = params.get('show_converted');
    if (!showConverted) return;
    dispatch(
      setFilter({
        leads: {
          includeConverted: showConverted === 'yes',
        },
      }),
    );
    setValue('converted', showConverted === 'yes' ? 'yes' : 'no');
  }, [search, dispatch, setValue]);

  return {
    form,
    sourceOptions,
    isLoading,
  };
}

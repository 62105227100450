import { Button, Loader } from '@kanda-libs/ks-design-library';
import { COPY } from './constants';
import useLeadDetailsConfirmDetails from './useLeadDetailsConfirmDetailsModal';

export interface LeadConfirmDetailsModalContentProps {
  handleClose: () => void;
}

const LeadConfirmDetailsModalContent: React.FunctionComponent<LeadConfirmDetailsModalContentProps> =
  function ({ handleClose }) {
    const { onClick, isSubmitting } = useLeadDetailsConfirmDetails(handleClose);
    return (
      <div className="flex flex-col">
        <h1 className="text-16-24-em mb-4">Confirm Job Details</h1>
        <h3 className="text-14-22 text-neutral-700 mb-5">{COPY}</h3>
        <div className="grid grid-cols-2 gap-x-2 md:gap-x-6 mt-3">
          <Button.Text
            id="lead-confirm-details-close"
            label="No"
            variant="outline"
            disabled={isSubmitting}
            onClick={handleClose}
          />
          <Button.Text
            id="lead-confirm-details-confirm"
            label="Yes"
            disabled={isSubmitting}
            onClick={onClick}
          />
          <Loader isLoading={isSubmitting} />
        </div>
      </div>
    );
  };

export default LeadConfirmDetailsModalContent;

// getNonArchivedLeads,
//       getActiveLeads,
//       getNonConvertedLeads,

import type { Enquiry } from '@kanda-libs/ks-frontend-services';

export const leadIsNotArchived = (lead: Enquiry): boolean =>
  lead.archived && lead.archived === 'no';

export const getNonArchivedLeads = (leads: Enquiry[]): Enquiry[] =>
  leads.filter(leadIsNotArchived);

export const leadIsActive = (lead: Enquiry): boolean => {
  const state = lead.current_state;
  if (!state) return false;
  return [
    'contact_agreed',
    'ask_for_quote',
    'quoted',
    'preferences_set',
    'submitted',
    'converted_to_job',
  ].includes(state);
};

export const getActiveLeads = (leads: Enquiry[]): Enquiry[] =>
  leads.filter(leadIsActive);

export const leadIsConverted = (lead: Enquiry): boolean => {
  const state = lead.current_state;
  if (!state) return false;
  return state === 'converted_to_job';
};

export const getNonConvertedLeads = (leads: Enquiry[]): Enquiry[] =>
  leads.filter((lead) => !leadIsConverted(lead));

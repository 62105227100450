import { BreakPoints } from '@kanda-libs/ks-design-library';
import { Helmet } from 'components';
import MyAccountFinanceEverywhereLinkProvider from './context';
import MyAccountFinanceEverywhereLinkDesktop from './Desktop';
import MyAccountFinanceEverywhereLinkMobile from './Mobile';

const MyAccountFinanceEverywhereLink: React.FunctionComponent = function () {
  return (
    <MyAccountFinanceEverywhereLinkProvider>
      <Helmet title="Team Settings" />
      <BreakPoints
        mobile={<MyAccountFinanceEverywhereLinkMobile />}
        desktop={<MyAccountFinanceEverywhereLinkDesktop />}
      />
    </MyAccountFinanceEverywhereLinkProvider>
  );
};

export default MyAccountFinanceEverywhereLink;

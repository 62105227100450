import {
  ModalContainer,
  ModalLayoutCenter,
} from '@kanda-libs/ks-design-library';
import type { MutableRefObject } from 'react';
import { MODAL_ID } from './constants';
import LeadConfirmDetailsModalContent from './Content';

const LeadConfirmDetailsModal: React.FunctionComponent = function () {
  return (
    <ModalContainer id={MODAL_ID} opacity={50}>
      {({ id, ref, handleClose }) => (
        <ModalLayoutCenter
          id={id}
          onClose={handleClose}
          ref={ref as MutableRefObject<HTMLDivElement>}
          className="w-10/12 md:w-120"
        >
          <LeadConfirmDetailsModalContent handleClose={handleClose} />
        </ModalLayoutCenter>
      )}
    </ModalContainer>
  );
};

export default LeadConfirmDetailsModal;

import type {
  TableHeaderColumn,
  TableProps,
} from '@kanda-libs/ks-component-ts';
import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import { URLS } from 'config';
import { useLeadsContext } from 'pages/Leads/context';
import type { FormattedLead } from 'pages/Leads/helpers';
import { useCallback, useEffect, useRef } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { scrollToTop } from 'utils';
import { TABLE_COLUMNS } from './constants';

export interface LeadsTableHook {
  tableProps: TableProps;
}

export default function useLeadsTable(): LeadsTableHook {
  const {
    leads,
    onAction,
    pageIndex,
    perPage,
    totalPages,
    query,
    setPage,
    numItems,
    isLoading,
  } = useLeadsContext();

  const { push } = useHistory();

  const onRowClicked = useCallback(
    (row?: StringIndexedObject) => {
      if (!row) return;
      const original = row?.original as FormattedLead;
      if (!original) return;
      const { id, currentState } = original;
      const url = currentState === 'converted_to_job' ? URLS.job : URLS.lead;
      scrollToTop();
      push(generatePath(url, { id }));
    },
    [push],
  );

  const queryRef = useRef<string>(query);
  // Effect resets page index to 0 when query changes - when user searches
  useEffect(() => {
    if (queryRef.current && queryRef.current === query) return;
    setPage(0);
  }, [query, setPage]);

  useEffect(() => {
    if (numItems === 0) {
      setPage(0);
      return;
    }
    const maxPage = Math.ceil(numItems / perPage);
    if (pageIndex <= maxPage - 1) return;
    setPage(maxPage - 1);
  }, [setPage, numItems, pageIndex, perPage]);

  return {
    tableProps: {
      data: leads,
      onRowClicked,
      pageIndex,
      totalPages,
      setPage,
      columns: TABLE_COLUMNS as unknown as TableHeaderColumn[],
      onAction,
      isLoading,
    },
  };
}

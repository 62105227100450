export const ICONS = {
  document: {
    icon: 'file',
    stroke: 'turquoise-300',
    size: 28,
    className: 'min-w-7 min-h-7',
  },
  delete: {
    icon: 'remove',
    stroke: 'neutral-600',
    size: 28,
    className: 'min-w-7 min-h-7',
  },
};

import LeadConvertToJob from 'pages/Lead/components/LeadConvertToJob';
import LeadGoToJob from 'pages/Lead/components/LeadGoToJob';
import LeadUnarchive from 'pages/Lead/components/LeadUnarchive';
import useLeadMobileFooter from './useLeadMobileFooter';

const LeadMobileLayoutFooter: React.FunctionComponent = function () {
  const { display } = useLeadMobileFooter();
  return (
    <div className="flex flex-row gap-x-4 justify-end -mx-2 z-[99999]">
      {display === 'unarchive' && <LeadUnarchive />}
      {display === 'convert' && <LeadConvertToJob />}
      {display === 'go_to_job' && <LeadGoToJob />}
    </div>
  );
};

export default LeadMobileLayoutFooter;

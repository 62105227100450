import { useLeadContext } from 'pages/Lead/context';
import { useMemo } from 'react';

export type LeadQuoteDetailsDisplay = 'lead' | 'quote';

export interface LeadQuoteDetailsHook {
  show: LeadQuoteDetailsDisplay;
}

export default function useLeadQuoteDetails(): LeadQuoteDetailsHook {
  const { lead, archived, isLoading } = useLeadContext();

  const show = useMemo<LeadQuoteDetailsDisplay>(() => {
    if (isLoading || archived) return 'lead';
    const isQuoted = Boolean(lead?.enquiry_info?.quote?.name);
    if (isQuoted) return 'quote';
    return 'lead';
  }, [isLoading, archived, lead]);

  return {
    show,
  };
}

import { Icon, SkeletonLoader } from '@kanda-libs/ks-design-library';
import { Document } from '@kanda-libs/ks-frontend-services';
import { ReactComponent as JobPDF } from 'assets/job-pdf.svg';
import { ICONS } from './constants';
import useFinalFileCard from './useFinalFileCard';

export interface LeadQuotePDFProps {
  document: Document | undefined;
  isLoading: boolean;
}

const LeadQuotePDF: React.FunctionComponent<LeadQuotePDFProps> = function ({
  document,
  isLoading,
}) {
  const { name, onClick } = useFinalFileCard(document);
  return (
    <div className="w-full flex flex-col flex-1">
      <button
        type="button"
        aria-label="quote-open"
        onClick={onClick}
        className="border-solid border overflow-hidden border-neutral-200 relative rounded-t-xl max-h-38 p-3 bg-neutral-200"
      >
        <JobPDF className="mx-auto" />
      </button>
      <div className="relative border border-t-0 rounded-b-xl border-neutral-200 bg-neutral-000 min-h-12 overflow-hidden">
        <div className="flex flex-row justify-between gap-x-3 px-3 py-2.5">
          <div className="flex flex-row w-full gap-x-3">
            <Icon {...ICONS.document} isLoading={isLoading} />
            <div className="w-full flex relative">
              <span className="absolute left-0 right-0 top-0.75 text-14-22 overflow-hidden whitespace-nowrap text-ellipsis">
                {isLoading ? <SkeletonLoader isLoading /> : name}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadQuotePDF;

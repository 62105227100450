import { ModalsWrapperContext } from '@kanda-libs/ks-design-library';
import { useCallback, useContext, useMemo } from 'react';

export interface TagDownloadModalHook {
  modalId: string;
  onClick: () => void;
}

export default function useTagDownloadModal(id: string): TagDownloadModalHook {
  const { showModal } = useContext(ModalsWrapperContext);

  const modalId = useMemo(() => `tag-download-modal-${id}`, [id]);

  const onClick = useCallback(() => showModal(modalId), [showModal, modalId]);

  return { onClick, modalId };
}

import { createSelector } from '@reduxjs/toolkit';
import { type RootState } from 'store';

export const getFiltersState = (state: RootState) => state.filters;

export const getFilters = createSelector(
  getFiltersState,
  (state) => state.filters,
);

export const getLeadsFilters = createSelector(
  getFilters,
  (state) => state.leads,
);

export const getLeadsSourceFilter = createSelector(
  getLeadsFilters,
  (state) => state.source,
);

export const getLeadsConvertedFilter = createSelector(
  getLeadsFilters,
  (state) => state.includeConverted,
);

const filtersSelectors = {
  getFilters,
  getLeadsFilters,
  getLeadsSourceFilter,
};

export default filtersSelectors;

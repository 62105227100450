import { Button, type ButtonTextProps } from '@kanda-libs/ks-design-library';

import useLeadGoToJob from './useLeadGoToJob';

export interface LeadGoToJobProps {
  size?: ButtonTextProps['size'];
}

const LeadGoToJob: React.FunctionComponent<LeadGoToJobProps> = function ({
  size = 48,
}) {
  const { onClick } = useLeadGoToJob();
  return (
    <Button.Text
      id="lead-go-to-job"
      size={size}
      onClick={onClick}
      icon="arrow-right"
      label="Go to Job"
      className="w-full"
    />
  );
};

export default LeadGoToJob;

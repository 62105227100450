export const CLASS_NAMES = {
  container: 'grid grid-cols-[auto_minmax(0,1fr)] gap-x-4 md:gap-x-10 gap-y-2',
  title: 'text-12-18-em-up text-neutral-600 my-auto',
  price: {
    wrapper: 'ml-auto',
    pounds: 'text-18-22-em',
    cents: 'text-10-14-em mt-px',
  },
  total: {
    wrapper: 'ml-auto',
    pounds: 'text-20-24-em',
    cents: 'text-12-18-em',
  },
  border: 'col-span-2 border-b border-neutral-300',
};

export const PRICE_PROPS = {
  className: CLASS_NAMES.price.wrapper,
  poundsClassName: CLASS_NAMES.price.pounds,
  centsClassName: CLASS_NAMES.price.cents,
  color: 'neutral-500',
};

export const TOTAL_PROPS = {
  className: CLASS_NAMES.total.wrapper,
  poundsClassName: CLASS_NAMES.total.pounds,
  centsClassName: CLASS_NAMES.total.cents,
  color: 'green-500',
};

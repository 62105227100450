import { useIsDesktop, type IconProps } from '@kanda-libs/ks-design-library';
import { useLeadContext } from 'pages/Lead/context';
import {
  formatBudget,
  getInterestInFinance,
  type InterestInFinance,
} from 'pages/Leads/helpers';
import { useMemo } from 'react';
import { formatWorkTypeToString } from 'utils';
import { ICON_PROPS, SEND_QUOTE_STATUSES } from './constants';
import { getLabel } from './helpers';

export type ActionButtonDisplay =
  | undefined
  | 'unarchive'
  | 'convert'
  | 'go_to_job';

export interface LeadQuoteDetailsLeadHook {
  iconProps: IconProps;
  label: string;
  budget: string;
  workType: string;
  interestInFinance: InterestInFinance;
  display: ActionButtonDisplay;
  description: string | undefined;
  isLoading: boolean;
  isSubmitting: boolean;
}

export default function useLeadQuoteDetailsLead(): LeadQuoteDetailsLeadHook {
  const { lead, archived, converted, isLoading, isSubmitting } =
    useLeadContext();

  const currentStatus = useMemo(() => lead?.current_state, [lead]);

  const isDesktop = useIsDesktop();

  const iconProps: IconProps = useMemo(() => {
    const size = isDesktop ? 64 : 20;
    if (isLoading || !currentStatus)
      return {
        size,
        icon: 'send',
        stroke: 'green-500',
      };
    if (archived)
      return {
        size,
        icon: 'archive',
        stroke: 'orange-200',
      };
    const baseProps = ICON_PROPS[currentStatus];
    return {
      size,
      ...baseProps,
    };
  }, [isLoading, isDesktop, archived, currentStatus]);

  const label = useMemo(() => {
    if (archived) return 'Lead Archived';
    return getLabel(currentStatus);
  }, [archived, currentStatus]);

  const budget = useMemo(() => formatBudget(lead?.enquiry_info), [lead]);

  const workType = useMemo(() => {
    const leadWorkType = lead?.enquiry_info?.job_info?.work_type;
    if (!leadWorkType) return 'Not answered';
    return formatWorkTypeToString(leadWorkType);
  }, [lead]);

  const description = useMemo(() => {
    if (!lead || !workType) return undefined;
    const infoDescription = lead?.enquiry_info?.job_info?.job_items?.[0]?.title;
    if (!infoDescription) return undefined;

    if (
      infoDescription
        .toLocaleLowerCase()
        .includes(`enquiry from customer for ${workType.toLocaleLowerCase()}`)
    )
      return undefined;
    return infoDescription;
  }, [lead, workType]);

  const interestInFinance = useMemo(
    () => getInterestInFinance(lead?.enquiry_info),
    [lead],
  );

  const display: ActionButtonDisplay = useMemo(() => {
    if (isLoading || archived || !lead) return undefined;
    const status = lead.current_state;
    if (converted) return 'go_to_job';
    return SEND_QUOTE_STATUSES.includes(status) ? 'convert' : undefined;
  }, [archived, converted, lead, isLoading]);

  return {
    iconProps,
    label,
    budget,
    workType,
    interestInFinance,
    display,
    description,
    isLoading,
    isSubmitting,
  };
}

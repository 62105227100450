import { useForm, type UseFormReturn } from '@kanda-libs/ks-component-ts';
import {
  useToast,
  type StringIndexedObject,
} from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import { useMyAccountFinanceEverywhereLinkContext } from 'pages/MyAccount/FinanceEverywhereLink/context';
import { useCallback, useMemo } from 'react';
import { extractErrorMessage } from 'utils';
import { formatTagBody, tradingNameToSlug } from './helpers';

export interface NewTagFormFormValues {
  name: string;
  source?: string;
}

export interface NewTagFormFormHook {
  form: UseFormReturn<NewTagFormFormValues>;
  onSubmit: (formValues: StringIndexedObject<NewTagFormFormValues>) => void;
  isSubmitting: boolean;
  onBlur: () => void;
}

export default function useNewTagFormForm(
  successCallback: () => void,
): NewTagFormFormHook {
  const { company, isSubmitting } = useMyAccountFinanceEverywhereLinkContext();
  const dispatch = useAppDispatch();
  const onApiError = useApiError('Unknown error adding link');
  const { showSuccess } = useToast();

  const tradingName = useMemo(
    () =>
      company?.contact_info?.trading_name ||
      company?.limited_company_info?.company_name ||
      company?.sole_trader_info?.trading_name,
    [company],
  );

  const defaultValues = useMemo(() => {
    if (!tradingName) return undefined;
    return {
      name: tradingNameToSlug(tradingName),
    };
  }, [tradingName]);

  const form = useForm<NewTagFormFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
  });

  const { setError } = form;

  const onSubmit = useCallback(
    (formValues: StringIndexedObject<NewTagFormFormValues>) => {
      const body = formatTagBody(formValues as unknown as NewTagFormFormValues);
      dispatch(
        actions.postTag({
          body,
          onError: (err) => {
            onApiError(err);
            const { message } = err;
            if (!message) return;
            const extracted = extractErrorMessage(err);
            if (extracted === `tag name '${body.name}' already existed`) {
              setError('name', {
                type: '400',
                message: `Tag name '${body.name}' is already in use - please choose something different`,
              });
            }
          },
          onSuccess: () => {
            showSuccess('Link created');
            successCallback();
          },
        }),
      );
    },
    [dispatch, onApiError, showSuccess, setError, successCallback],
  );

  const onBlur = useCallback(
    () => form.setValue('name', tradingNameToSlug(form.getValues('name'))),
    [form],
  );

  return {
    form,
    onSubmit,
    isSubmitting,
    onBlur,
  };
}

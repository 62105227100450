import { Logo } from '@kanda-libs/ks-design-library';
import type { RefObject } from 'react';
import { QRCode } from 'react-qrcode-logo';
import useTagRender from './useTagRender';

export type TagRenderSize = 'S' | 'M' | 'L' | 'XL';

export interface TagRenderProps {
  url: string;
  logoBase64: string;
  logoSize: number[];
  scale?: TagRenderSize;
  elementRef?: RefObject<HTMLDivElement>;
}

const TagRender: React.FunctionComponent<TagRenderProps> = function ({
  url,
  logoBase64,
  logoSize,
  scale = 'S',
  elementRef,
}) {
  const {
    size,
    quietZone,
    eyeRadius,
    logoPadding,
    logoWidth,
    logoHeight,
    kandaLogoWidth,
    kandaLogoHeight,
    width,
    height,
    padding,
    border,
    textScanHeight,
    textScanLineHeight,
    textFinanceHeight,
    textFinanceLineHeight,
    marginTop,
  } = useTagRender(logoSize, scale);
  return (
    <div
      ref={elementRef}
      className="bg-neutral-000 border-neutral-300 mr-auto"
      style={{
        width: `${width}px`,
        height: `${height}px`,
        minWidth: `${width}px`,
        minHeight: `${height}px`,
        padding: `${padding}px`,
        border: `${border}px solid #e0e7f0`,
      }}
    >
      <div className="flex flex-row justify-between">
        <QRCode
          value={url}
          ecLevel="M"
          qrStyle="dots"
          eyeRadius={eyeRadius}
          logoImage={logoBase64}
          logoWidth={logoWidth}
          logoHeight={logoHeight}
          logoPadding={logoPadding}
          size={size}
          quietZone={quietZone}
          eyeColor="#0c6355"
          fgColor="#0c6355"
          removeQrCodeBehindLogo
        />
        <div
          className="flex flex-col my-auto"
          style={{ paddingRight: `${padding}px` }}
        >
          <p
            className="text-center uppercase"
            style={{
              fontSize: `${textScanHeight}px`,
              lineHeight: `${textScanLineHeight}px`,
              fontWeight: 'bold',
            }}
          >
            Scan to enquire
          </p>
          <p
            className="text-center"
            style={{
              fontSize: `${textFinanceHeight}px`,
              lineHeight: `${textFinanceLineHeight}px`,
              fontWeight: 'bold',
              marginTop: `${marginTop}px`,
            }}
          >
            Finance available through
          </p>
          <Logo
            width={kandaLogoWidth}
            height={kandaLogoHeight}
            className="mx-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default TagRender;

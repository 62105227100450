import { Button, Popover } from '@kanda-libs/ks-design-library';
import LeadActionsMenu from 'pages/Lead/components/LeadActionsMenu';
import LeadConfirmDetails from 'pages/Lead/components/LeadConfirmDetails';
import LeadConvertToJob from 'pages/Lead/components/LeadConvertToJob';
import LeadEditDetails from 'pages/Lead/components/LeadEditDetails';
import LeadGoToJob from 'pages/Lead/components/LeadGoToJob';
import LeadUnarchive from 'pages/Lead/components/LeadUnarchive';
import useActionButtons from './useActionButtons';

const ActionButtons: React.FunctionComponent = function ({}) {
  const { display } = useActionButtons();
  return (
    <div className="flex flex-row gap-x-6 -mr-4">
      {display === 'unarchive' && <LeadUnarchive />}
      {display === 'confirm_details' && (
        <>
          <LeadEditDetails />
          <LeadConfirmDetails />
        </>
      )}
      {display === 'convert' && <LeadConvertToJob />}
      {display === 'go_to_job' && <LeadGoToJob />}
      <Popover.Standard
        id="lead-details-header-more"
        className="mr-4"
        button={<Button.Icon id="lead-details-header-more" icon="more" />}
        yMargin="-mt-8"
        xMargin="mr-6"
        right
      >
        {({ handleClose }) => (
          <LeadActionsMenu handleClose={handleClose} callback={handleClose} />
        )}
      </Popover.Standard>
    </div>
  );
};

export default ActionButtons;

import { BreakPoints } from '@kanda-libs/ks-design-library';
import type { ReactNode } from 'react';
import OptionPopoverDesktop from './Desktop';
import OptionPopoverMobile from './Mobile';

export interface OptionPopoverProps {
  children: ReactNode;
  popoverId: string;
  iconStroke?: string;
}

const OptionPopover: React.FunctionComponent<OptionPopoverProps> = function ({
  children,
  popoverId,
  iconStroke = 'neutral-500',
}) {
  return (
    <BreakPoints
      desktop={
        <OptionPopoverDesktop popoverId={popoverId} iconStroke={iconStroke}>
          {children}
        </OptionPopoverDesktop>
      }
      mobile={
        <OptionPopoverMobile popoverId={popoverId} iconStroke={iconStroke}>
          {children}
        </OptionPopoverMobile>
      }
    />
  );
};

export default OptionPopover;

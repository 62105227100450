import type { StringIndexedObject } from '@kanda-libs/ks-component-ts';
import { useToast } from '@kanda-libs/ks-design-library';
import type { Job } from '@kanda-libs/ks-frontend-services';
import { BANNED_CIDS, URLS } from 'config';
import { pipe } from 'fp-ts/lib/function';
import { useCurrentCompany } from 'hooks';
import { useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  filterForUnregulatedRates,
  getAllRateKeyNames,
  getCompanyInitialRates,
  getFinanceRateNameKey,
  getSelectedFinanceRates,
  urlWithParams,
} from 'utils';
import { BASE_DEPOSIT, SOLAR_DEPOSIT } from './CreateJob-constants';
import {
  formatDuplicate,
  formatExampleDraft,
  getJobType,
} from './CreateJob-functions';
import useFetchDraft from './CreateJob-useFetchDraft';

export interface ContainerComponentChildrenArgs {
  initialData: StringIndexedObject;
}

export interface ContainerComponentProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

interface CreateJobLocationState {
  newQuote?: Job;
  duplicate?: Job;
}

const ContainerComponent = ({ children }) => {
  const { company } = useCurrentCompany();

  const { draft, hasDraft, isValidating } = useFetchDraft();

  const { showWarning } = useToast();
  const { push, location } = useHistory();
  const { state } = useLocation<CreateJobLocationState>();

  const jobType = useMemo(() => getJobType(company, draft), [company, draft]);

  // Get dropped file from home if dropped
  const droppedFile = state?.newQuote;
  // Get job data to duplicate if clicked
  const duplicate = state?.duplicate || state?.duplicate;
  // // Get job data to duplicate & archived if clicked
  // const duplicateArchive =

  const solarJobType = useMemo(() => {
    if (!company) return 'standard';
    if (!company?.solar_company_info) return 'standard';
    return 'solar';
  }, [company]);

  const deposit = useMemo(() => {
    if (!company) return BASE_DEPOSIT;
    if (!company?.solar_company_info) return BASE_DEPOSIT;
    return SOLAR_DEPOSIT;
  }, [company]);

  const initialJobData = useMemo(() => {
    if (hasDraft && !draft) return undefined;
    if (draft) {
      if (draft?.flow_type === 'live')
        return {
          ...draft,
        };
      return { ...formatExampleDraft(draft) };
    }
    if (!company)
      return {
        ...deposit,
        checkout_options: ['decline_job'],
        job_type: solarJobType,
      };
    if (duplicate) return formatDuplicate(duplicate, company);
    return {
      ...deposit,
      checkout_options: ['decline_job'],
      job_type: solarJobType,
    };
  }, [hasDraft, draft, duplicate, solarJobType, deposit, company]);

  const draftIsLoading = useMemo(() => hasDraft && !draft, [hasDraft, draft]);

  const initialData = useMemo(
    () => ({
      isLoading: !company || !!isValidating || draftIsLoading,
      company,
      jobType,
      job: initialJobData,
      ...(jobType !== 'kanda' && { droppedFile }),
    }),
    [
      draftIsLoading,
      company,
      jobType,
      droppedFile,
      isValidating,
      initialJobData,
    ],
  );

  const missingRateRef = useRef(false);
  useEffect(() => {
    if (missingRateRef.current) return;
    if (!duplicate || !company) return;
    const companyRates = company?.finance_rates;
    if (!companyRates) return;
    const formatted = formatDuplicate(duplicate, company);
    const formattedDuplicateRates = getSelectedFinanceRates(
      companyRates,
      formatted.finance_options as unknown as string[] | undefined,
    )?.map((rate) => getFinanceRateNameKey(rate));
    const formattedCompanyRates = pipe(
      companyRates,
      getCompanyInitialRates,
      filterForUnregulatedRates,
      getAllRateKeyNames,
    );
    if (
      !formattedDuplicateRates ||
      !formattedDuplicateRates.every((rateKey) =>
        formattedCompanyRates.includes(rateKey),
      )
    ) {
      showWarning(
        'Some APRs on this job are no longer available, please select new rates',
      );
    }
    missingRateRef.current = true;
  }, [duplicate, showWarning, company]);

  useEffect(() => {
    if (!company) return;
    const cid = company?.cid;
    if (!cid) return;
    const banned = BANNED_CIDS.includes(cid);
    if (banned) push(URLS.jobs);
  }, [push, company]);

  useEffect(() => {
    if (!hasDraft || !draft) return;
    const { flow_type: flowType, id = '' } = draft;
    const isExampleUrl = location.pathname.includes('create-example-job');
    if (flowType === 'example' && !isExampleUrl) {
      push(urlWithParams(URLS.createExampleJob, { id }));
      return;
    }
    if (flowType === 'live' && isExampleUrl) {
      push(urlWithParams(URLS.createJob, { id }));
    }
  }, [draft, hasDraft, location, push]);

  return children({ initialData });
};

ContainerComponent.displayName = 'CreateJob-container';

export default ContainerComponent;

import { useToast } from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { URLS } from 'config';
import useApiError from 'hooks/useApiError';
import { useLeadContext } from 'pages/Lead/context';
import { useCallback } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { scrollToTop } from 'utils';

export interface LeadEditDetailsHook {
  onClick: () => void;
  isSubmitting: boolean;
}

export default function useLeadEditDetails(): LeadEditDetailsHook {
  const { id, isSubmitting, setIsSubmitting } = useLeadContext();
  const dispatch = useAppDispatch();
  const { showSuccess } = useToast();
  const { push } = useHistory();
  const onError = useApiError('Unknown error converting lead to job');

  const onClick = useCallback(() => {
    if (!id) return;
    setIsSubmitting(true);
    dispatch(
      actions.postEnquiryConvertJob({
        params: { id },
        onError: (err) => {
          setIsSubmitting(false);
          onError(err);
        },
        onSuccess: () => {
          scrollToTop();
          setIsSubmitting(false);
          showSuccess('Converted to job - edit necessary details');
          push(generatePath(URLS.createJobFromDraft, { id }));
        },
      }),
    );
  }, [id, dispatch, onError, showSuccess, push, setIsSubmitting]);

  return {
    onClick,
    isSubmitting,
  };
}

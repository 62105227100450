import { Text } from '@kanda-libs/ks-design-library';
import InfoCard from 'components/InfoCard';
import LeadConfirmDetails from '../../LeadConfirmDetails';
import LeadEditDetails from '../../LeadEditDetails';
import LeadItemsDisplay from '../../LeadItemsDisplay';
import LeadItemsFinance from '../../LeadItemsFinance';
import LeadQuotePDF from '../../LeadQuotePDF';
import LeadWorkType from '../../LeadWorkType';
import useLeadQuoteDetailsQuote from './useLeadQuoteDetailsQuote';

const LeadQuoteDetailsQuoteContent: React.FunctionComponent = function () {
  const { quote, leadItems, isLoading } = useLeadQuoteDetailsQuote();
  return (
    <InfoCard title="Quote Details - click edit to change any of this information">
      <div className="flex flex-col md:px-4 lg:px-16 md:py-8 gap-y-6">
        <div className="flex flex-col">
          <Text
            text="Quote PDF - click to view"
            isLoading={isLoading}
            className="text-14-22-em text-neutral-700 mb-2"
          />
          <LeadQuotePDF document={quote} isLoading={isLoading} />
        </div>
        <LeadItemsDisplay leadItems={leadItems} isLoading={isLoading} />
        <LeadWorkType />
        <LeadItemsFinance isLoading={isLoading} />
        <div className="flex flex-col gap-y-4 w-full max-w-80 mx-auto">
          <LeadConfirmDetails />
          <LeadEditDetails className="w-full" />
        </div>
      </div>
    </InfoCard>
  );
};

export default LeadQuoteDetailsQuoteContent;

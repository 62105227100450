import { Link } from 'react-router-dom';

import { BreakPoints, Button } from '@kanda-libs/ks-design-library';
import LeadsActionBanner from 'components/LeadsActionBanner';

import { CLASS_NAMES } from './Banner-constants';
import Container from './Banner-container';
import {
  ActiveFilters,
  ArchiveButton,
  CompleteAccountBadge,
  DesktopSearch,
  NewJobButton,
  Title,
} from './components';
import JobTableFilterButton from './components/JobTableFilterButton';
import UpgradeAccountBadge from './components/UpgradeAccountBadge';

import { URLS } from '../../../../config';

const ViewComponent = ({}) => (
  <Container>
    {({
      showOptions,
      setupInProgress,
      hasActiveFilters,
      archive,
      showSubscriptionBanner,
      hideButton,
    }) => (
      <div className={CLASS_NAMES.container}>
        <LeadsActionBanner />
        {setupInProgress && !archive && (
          <div className="mb-8 md:mb-16">
            <CompleteAccountBadge />
          </div>
        )}
        {!setupInProgress && showSubscriptionBanner && (
          <div className="mb-8 md:mb-16">
            <UpgradeAccountBadge />
          </div>
        )}
        <div className={CLASS_NAMES.header}>
          {archive && (
            <div className="hidden md:block md:flex">
              <Link to={URLS.jobs} className="-ml-3">
                <Button.Icon
                  id="home-banner-back"
                  icon="arrow-left"
                  size={40}
                />
              </Link>
            </div>
          )}
          <Title archive={archive} />
          <BreakPoints desktop={<DesktopSearch />} />
          <div className={CLASS_NAMES.options}>
            {showOptions && !archive && (
              <>
                <JobTableFilterButton />
                <ArchiveButton />
              </>
            )}
            {!hideButton && <NewJobButton />}
          </div>
        </div>
        {hasActiveFilters && <ActiveFilters />}
      </div>
    )}
  </Container>
);

ViewComponent.displayName = 'Banner-view';

export default ViewComponent;

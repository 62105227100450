import { actions, Document, JobItem } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { useLeadContext } from 'pages/Lead/context';
import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { PLACEHOLDER_ITEMS } from './constants';

export interface LeadQuoteDetailsQuoteHook {
  quote: Document | undefined;
  leadItems: JobItem[];
  isLoading: boolean;
}

export default function useLeadQuoteDetailsQuote(): LeadQuoteDetailsQuoteHook {
  const { lead, isLoading: contextLoading } = useLeadContext();
  const documents = useSelector(selectors.document.getEntities);
  const documentsIsLoading = useSelector(selectors.document.getIsLoading);
  const dispatch = useAppDispatch();

  const quoteId = useMemo(() => {
    if (!lead) return undefined;
    return lead?.enquiry_info?.quote?.id;
  }, [lead]);

  const quote = useMemo(() => {
    if (!quoteId) return undefined;
    return documents?.[quoteId];
  }, [quoteId, documents]);

  const leadItems = useMemo(
    () => lead?.enquiry_info?.job_info?.job_items || PLACEHOLDER_ITEMS,
    [lead],
  );

  const isLoading = useMemo(
    () => contextLoading || documentsIsLoading,
    [contextLoading, documentsIsLoading],
  );

  const docRef = useRef<boolean>(false);
  useEffect(() => {
    if (quote || !quoteId || docRef.current) return;
    docRef.current = true;
    dispatch(
      actions.getDocument({
        params: { id: quoteId },
        forceReload: true,
      }),
    );
  }, [quote, quoteId, dispatch]);

  return {
    quote,
    leadItems,
    isLoading,
  };
}

import { useForm, type UseFormReturn } from '@kanda-libs/ks-component-ts';
import {
  ModalsWrapperContext,
  useToast,
  type StringIndexedObject,
} from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { APP_ENV } from 'config';
import { pipe } from 'fp-ts/lib/function';
import useApiError from 'hooks/useApiError';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import {
  filterForBNPLRates,
  filterForInterestBearingRates,
  filterForInterestFreeRates,
  filterForPrimaryRates,
  groupByAprAndDuration,
  type GroupedByAprAndDuration,
} from 'utils';
import { useMyAccountFinanceRatesContext } from '../../context';
import { CUTOFF, MODAL_ID, QA_CUTOFF } from './constants';
import { formatEnrolmentBody } from './helpers';

export interface MyAccountFinanceRatesFormValues {
  enabled: string[];
}

export interface MyAccountFinanceRatesFormHook {
  form: UseFormReturn<MyAccountFinanceRatesFormValues>;
  onSubmit: (
    formValues: StringIndexedObject<MyAccountFinanceRatesFormValues>,
  ) => void;
  onClick: () => void;
  interestFreeRates: GroupedByAprAndDuration | undefined;
  interestBearingRates: GroupedByAprAndDuration | undefined;
  bnplRates: GroupedByAprAndDuration | undefined;
  locked: boolean;
  isSubmitting: boolean;
}

export default function useMyAccountFinanceRatesForm(): MyAccountFinanceRatesFormHook {
  const { combinedRates, enrolment, me, cid, isLoading, isSubmitting } =
    useMyAccountFinanceRatesContext();

  const dispatch = useAppDispatch();

  const onError = useApiError(
    'Unknown error updating rates - please contact Kanda',
  );

  const { showSuccess } = useToast();

  const defaultValues = useMemo(() => {
    if (isLoading) return undefined;
    const added = enrolment?.rate_request.added;
    const toAdd = enrolment?.rate_request?.to_add;
    if (!added) return undefined;
    const enabled = [...added, ...(toAdd ? [...toAdd] : [])].filter(
      (key, index, array) => array.indexOf(key) === index,
    );
    return { enabled };
  }, [isLoading, enrolment]);

  const { showModal } = useContext(ModalsWrapperContext);

  const onClick = useCallback(() => showModal(MODAL_ID), [showModal]);

  const form = useForm<MyAccountFinanceRatesFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
  });

  const onSubmit = useCallback(
    (formValues: StringIndexedObject<MyAccountFinanceRatesFormValues>) => {
      if (!cid || !enrolment || !me) return;
      const fv = formValues as unknown as MyAccountFinanceRatesFormValues;
      const body = formatEnrolmentBody(fv, me, enrolment);
      dispatch(
        actions.putEnrolment({
          body,
          useDevHeader: APP_ENV === 'qa',
          params: {
            id: cid,
          },
          onError,
          onSuccess: () => {
            showSuccess('Rate request submitted');
          },
        }),
      );
    },
    [cid, enrolment, me, dispatch, onError, showSuccess],
  );

  const interestFreeRates = useMemo(() => {
    if (isLoading || !combinedRates) return undefined;
    return pipe(
      combinedRates,
      filterForPrimaryRates,
      filterForInterestFreeRates,
      groupByAprAndDuration,
    );
  }, [isLoading, combinedRates]);

  const interestBearingRates = useMemo(() => {
    if (isLoading || !combinedRates) return undefined;
    return pipe(
      combinedRates,
      filterForPrimaryRates,
      filterForInterestBearingRates,
      groupByAprAndDuration,
    );
  }, [isLoading, combinedRates]);

  const bnplRates = useMemo(() => {
    if (isLoading || !combinedRates) return undefined;
    return pipe(
      combinedRates,
      filterForPrimaryRates,
      filterForBNPLRates,
      groupByAprAndDuration,
    );
  }, [isLoading, combinedRates]);

  const locked = useMemo(() => {
    if (isSubmitting) return true;
    const metadata = enrolment?.metadata;
    if (!metadata) return true;
    const created = new Date(metadata.created_at);
    const updated = new Date(metadata.updated_at);
    const diff = updated.getTime() - created.getTime();
    if (Math.abs(diff) < 10) return false;
    const now = new Date();
    const diffNow = now.getTime() - updated.getTime();
    const cutoff = APP_ENV === 'qa' ? QA_CUTOFF : CUTOFF;
    return diffNow < cutoff;
  }, [enrolment, isSubmitting]);

  useEffect(() => {
    if (isLoading) return;
    form.reset(defaultValues);
  }, [isLoading, form, defaultValues]);

  return {
    form,
    onSubmit,
    interestFreeRates,
    interestBearingRates,
    bnplRates,
    locked,
    onClick,
    isSubmitting,
  };
}

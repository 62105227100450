import { BreakPoints } from '@kanda-libs/ks-design-library';
import LeadConfirmDetailsModal from './components/LeadConfirmDetailsModal';
import LeadProvider from './context';
import LeadDesktop from './Desktop';
import LeadHelmet from './LeadHelmet';
import LeadMobile from './Mobile';

const Lead: React.FunctionComponent = function () {
  return (
    <LeadProvider>
      <LeadHelmet />
      <BreakPoints mobile={<LeadMobile />} desktop={<LeadDesktop />} />
      <LeadConfirmDetailsModal />
    </LeadProvider>
  );
};

export default Lead;

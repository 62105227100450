import type {
  Company,
  Enquiry,
  FinanceRate,
  InfoWorkType,
  WorkType,
} from '@kanda-libs/ks-frontend-services';
import { pipe } from 'fp-ts/lib/function';
import { useCurrentCompany } from 'hooks';
import {
  createContext,
  useContext,
  useMemo,
  useState,
  type Dispatch,
  type FunctionComponent,
  type ReactNode,
  type SetStateAction,
} from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import {
  calculateJobTotal,
  filterForRegulatedRates,
  formatLenderConfigs,
  getCurrentInfoWorkType,
  getValidFinanceRates,
  type FormattedLenderConfig,
} from 'utils';

export interface LeadContextType {
  lead: Enquiry | undefined;
  company: Company | undefined;
  archived: boolean;
  converted: boolean;
  hasQuote: boolean;
  defaultRates: FinanceRate[] | undefined;
  lenderConfigs: FormattedLenderConfig | undefined;
  workType: WorkType | undefined;
  validWorkType: boolean;
  id: string | undefined;
  isLoading: boolean;
  isSubmitting: boolean;
  setIsSubmitting: Dispatch<SetStateAction<boolean>>;
}

export const LeadContext = createContext<LeadContextType>({
  lead: undefined,
  company: undefined,
  archived: false,
  converted: false,
  hasQuote: false,
  defaultRates: undefined,
  lenderConfigs: undefined,
  workType: undefined,
  validWorkType: false,
  id: undefined,
  isLoading: false,
  isSubmitting: false,
  setIsSubmitting: () => {},
});

export const useLeadContext = () => useContext(LeadContext);

export interface LeadProviderProps {
  children: ReactNode;
}

const LeadProvider: FunctionComponent<LeadProviderProps> = function ({
  children,
}) {
  const lead = useSelector(selectors.enquiry.getEntity);
  const leadHasFetched = useSelector(selectors.enquiry.getHasFetched);
  const id = useSelector(selectors.enquiry.getId);
  const infoWorkTypes = useSelector(selectors.infoWorkType.getRawResponse) as
    | InfoWorkType[]
    | undefined;
  const infoWorkTypeHasFetched = useSelector(
    selectors.infoWorkType.getHasFetched,
  );

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { company } = useCurrentCompany();

  const archived = useMemo(() => {
    if (!lead) return false;
    return lead.archived === 'yes';
  }, [lead]);

  const converted = useMemo(() => {
    if (!lead) return false;
    return lead.current_state === 'converted_to_job';
  }, [lead]);

  const hasQuote = useMemo(() => {
    if (!lead) return false;
    return Boolean(lead.enquiry_info?.quote);
  }, [lead]);

  const workType = useMemo(
    () => lead?.enquiry_info?.job_info?.work_type,
    [lead],
  );

  const validWorkType = useMemo(() => {
    if (!workType) return false;
    const companyWorkTypes = company?.company_info?.work_types;
    if (!companyWorkTypes) return false;
    return companyWorkTypes.includes(workType);
  }, [workType, company]);

  const total = useMemo(() => {
    const items = lead?.enquiry_info?.job_info?.job_items;
    if (!items) return 0;
    return calculateJobTotal(items).totalIncVat;
  }, [lead]);

  const lenderConfigs = useMemo(() => {
    if (!company) return undefined;
    return formatLenderConfigs(company);
  }, [company]);

  const companyRates = useMemo(() => company?.finance_rates, [company]);

  const infoWorkType = useMemo(
    () => getCurrentInfoWorkType(companyRates, infoWorkTypes, workType),
    [companyRates, infoWorkTypes, workType],
  );

  const allRates = useMemo(
    () => getValidFinanceRates(infoWorkType, total, 0),
    [infoWorkType, total],
  );

  const defaultRates = useMemo(() => {
    if (!allRates) return undefined;
    return pipe(allRates, filterForRegulatedRates);
  }, [allRates]);

  const isLoading = useMemo(
    () => !leadHasFetched || !company || !infoWorkTypeHasFetched,
    [leadHasFetched, company, infoWorkTypeHasFetched],
  );

  const values = useMemo(
    () => ({
      lead,
      company: company || undefined,
      archived,
      converted,
      hasQuote,
      defaultRates,
      lenderConfigs,
      workType,
      validWorkType,
      id,
      isLoading,
      isSubmitting,
      setIsSubmitting,
    }),
    [
      lead,
      company,
      archived,
      converted,
      hasQuote,
      defaultRates,
      lenderConfigs,
      workType,
      validWorkType,
      id,
      isLoading,
      isSubmitting,
    ],
  );

  return <LeadContext.Provider value={values}>{children}</LeadContext.Provider>;
};

export default LeadProvider;

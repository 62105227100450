import TagDownloadModal from './TagDownloadModal';
import TagRender from './TagRender';
import useTagBanner from './useTagBanner';

export interface TagBannerProps {
  url: string;
  id: string;
  logoBase64: string;
}

const TagBanner: React.FunctionComponent<TagBannerProps> = function ({
  url,
  id,
  logoBase64,
}) {
  const { imageRef, size } = useTagBanner(url, id);
  return (
    <>
      <div className="w-0 h-0 overflow-hidden">
        <img src={logoBase64} alt={id} ref={imageRef} />
      </div>
      <div className="flex col-span-2 w-full h-[196px] relative overflow-x-scroll">
        <div className="absolute">
          {size && (
            <TagRender
              url={url}
              logoBase64={logoBase64}
              logoSize={size}
              scale="S"
            />
          )}
        </div>
      </div>
      {logoBase64 && size && (
        <div className="col-span-2">
          <TagDownloadModal
            id={id}
            url={url}
            logoBase64={logoBase64}
            logoSize={size}
          />
        </div>
      )}
    </>
  );
};

export default TagBanner;

// <div className="w-0 h-0 overflow-hidden">
//         {size && (
//           <TagRender
//             url={url}
//             logoBase64={logoBase64}
//             logoSize={size}
//             scale="XL"
//           />
//         )}
//       </div>

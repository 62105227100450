import type { SelectOption } from '@kanda-libs/ks-component-ts';

export const BASE_OPTION: SelectOption = {
  name: 'all',
  value: 'all',
};

export const SHOW_CONVERTED: SelectOption[] = [
  {
    name: 'Yes',
    value: 'yes',
  },
  {
    name: 'No',
    value: 'no',
  },
];

import { Price, Text } from '@kanda-libs/ks-design-library';
import type { Totals } from 'types';
import { CLASS_NAMES, PRICE_PROPS, TOTAL_PROPS } from './constants';

export interface LeadItemsTotalsProps {
  totals: Totals;
  isLoading?: boolean;
}

const LeadItemsTotals: React.FunctionComponent<LeadItemsTotalsProps> =
  function ({ totals, isLoading = false }) {
    return (
      <div className={CLASS_NAMES.container}>
        <Text
          className={CLASS_NAMES.title}
          text="Total exc. VAT"
          isLoading={isLoading}
        />
        <Price
          amount={totals.totalExcVat}
          {...PRICE_PROPS}
          isLoading={isLoading}
        />
        <Text className={CLASS_NAMES.title} text="VAT" isLoading={isLoading} />
        <Price
          amount={totals.totalVat}
          {...PRICE_PROPS}
          isLoading={isLoading}
        />
        <div className={CLASS_NAMES.border} />
        <Text
          className={CLASS_NAMES.title}
          text="Total inc. VAT"
          isLoading={isLoading}
        />
        <Price
          amount={totals.totalIncVat}
          {...TOTAL_PROPS}
          isLoading={isLoading}
        />
        <div className={CLASS_NAMES.border} />
        <Text
          className={CLASS_NAMES.title}
          text="Current Deposit"
          isLoading={isLoading}
        />
        <Price amount={0} {...TOTAL_PROPS} isLoading={isLoading} />
      </div>
    );
  };

export default LeadItemsTotals;

import { Icon, Text, TimeAgo } from '@kanda-libs/ks-design-library';
import InfoCard from 'components/InfoCard';
import { formatDateToDateString } from 'utils';
import useLeadPreferences from './useLeadPreferences';

const LeadPreferences: React.FunctionComponent = function ({}) {
  const {
    preferences,
    dateRequested,
    timeframe,
    callback,
    isLoading,
    hasQuote,
  } = useLeadPreferences();
  if (!preferences || hasQuote) return null;
  return (
    <InfoCard title="Contact Preferences" isLoading={isLoading}>
      {dateRequested && (
        <div className="flex flex-col mb-2">
          <Text
            text="Date Requested"
            isLoading={isLoading}
            className="text-14-22-em"
          />
          <div className="flex flex-row gap-x-2 text-14-22 text-neutral-700">
            <Icon
              icon="calendar"
              isLoading={isLoading}
              stroke="green-600"
              size={16}
              className="min-w-4 my-auto"
            />
            <Text
              text={formatDateToDateString(dateRequested)}
              isLoading={isLoading}
            />
            <p>-</p>
            <TimeAgo timestamp={dateRequested.getTime()} />
          </div>
        </div>
      )}
      {timeframe && (
        <div className="flex flex-col mb-2">
          <Text
            text="Urgency of Work"
            isLoading={isLoading}
            className="text-14-22-em"
          />
          <div className="flex flex-row gap-x-3">
            <Icon
              icon="alarm"
              isLoading={isLoading}
              stroke="green-600"
              size={16}
              className="min-w-4 my-auto"
            />
            <Text
              text={timeframe}
              isLoading={isLoading}
              className="text-14-22 text-neutral-700"
              skeletonProps={{
                width: 50,
              }}
            />
          </div>
        </div>
      )}
      {callback && (
        <div className="flex flex-col">
          <Text
            text="Best Time For Callback"
            isLoading={isLoading}
            className="text-14-22-em"
          />
          <div className="flex flex-row gap-x-3">
            <Icon
              icon="phone"
              isLoading={isLoading}
              stroke="green-600"
              size={16}
              className="min-w-4 my-auto"
            />
            <Text
              text={callback}
              isLoading={isLoading}
              className="text-14-22 text-neutral-700"
              skeletonProps={{
                width: 50,
              }}
            />
          </div>
        </div>
      )}
    </InfoCard>
  );
};

export default LeadPreferences;

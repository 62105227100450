import { Icon } from '@kanda-libs/ks-design-library';
import type { OptionPopoverProps } from '..';
import useOptionPopoverDesktop from './useOptionPopoverDesktop';

const OptionPopoverDesktop: React.FunctionComponent<OptionPopoverProps> =
  function ({ children, iconStroke }) {
    const { elementRef, visible } = useOptionPopoverDesktop();
    return (
      <div ref={elementRef} className="z-50 mb-px relative">
        <Icon
          icon="info"
          className="w-4 cursor-pointer min-w-4 text-neutral-500 ml-2"
          size={16}
          stroke={iconStroke}
        />
        {visible && (
          <div className="absolute bottom-8 right-0 px-4 py-5 border border-neutral-200 bg-neutral-000 rounded-lg shadow-popover min-w-60">
            {children}
          </div>
        )}
      </div>
    );
  };

export default OptionPopoverDesktop;

import { Banner, Button } from '@kanda-libs/ks-design-library';
import FinanceEverywhereBanner from 'components/FinanceEverywhereBanner';
import { URLS } from 'config';
import { Link } from 'react-router-dom';
import useLeadsActionBanner from './useLeadsActionBanner';

const LeadsActionBanner: React.FunctionComponent = function () {
  const { isLoading, title } = useLeadsActionBanner();
  if (isLoading) return null;
  if (!title) return <FinanceEverywhereBanner />;
  return (
    <div className="w-full mb-6 md:mb-10 -mt-8">
      <Banner>
        <div className="flex w-full flex-col">
          <p className="text-18-22-em text-neutral-900 mb-3">{title}</p>
          <Link to={`${URLS.leads}?show_converted=no`} className="ml-auto">
            <Button.Text
              id="leads-action-link"
              label="Take Action Now!"
              size={40}
              icon="send"
              left
            />
          </Link>
        </div>
      </Banner>
    </div>
  );
};

export default LeadsActionBanner;

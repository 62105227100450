import { ModalsWrapperContext } from '@kanda-libs/ks-design-library';
import { useLeadContext } from 'pages/Lead/context';
import { useCallback, useContext } from 'react';
import { MODAL_ID } from '../LeadConfirmDetailsModal/constants';

export interface LeadConfirmDetailsHook {
  onClick: () => void;
  isSubmitting: boolean;
  validWorkType: boolean;
}

export default function useLeadConfirmDetails(): LeadConfirmDetailsHook {
  const { validWorkType, isSubmitting } = useLeadContext();
  const { showModal } = useContext(ModalsWrapperContext);

  const onClick = useCallback(() => showModal(MODAL_ID), [showModal]);

  return {
    validWorkType,
    onClick,
    isSubmitting,
  };
}

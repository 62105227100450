export const CLASS_NAMES = {
  container: 'flex flex-1 md:my-auto',
  card: 'flex-col flex text-neutral-600 w-full border rounded-xl border-solid border-neutral-200 py-5 px-4',
  content: 'flex flex-col items-center my-auto pt-8 md:p-10 text-center',
  title: 'text-24-28-em md:text-28-32-em text-neutral-700 mb-6',
  description: 'text-16-24 text-neutral-600 mb-4 md:mb-8 md:w-120',
};

export const ICON_PROPS = {
  className: 'mb-8',
  stroke: 'turquoise-400',
  icon: 'search-file',
  size: 64,
};

export const TITLE = 'No results matching your search term';

export const BODY = 'Try searching a different term to find your leads';

export const DESCRIPTION =
  'Grab your shareable URLs in your account settings and share to start gathering leads through Kanda';

export const BUTTON_PROPS = {
  id: 'leads-empty-always-on-link',
  label: 'Get Your Shareable Link',
  left: true,
  icon: 'plus',
  size: 40,
};

import type { SelectOption } from '@kanda-libs/ks-component-ts';
import type { Enquiry } from '@kanda-libs/ks-frontend-services';
import { BASE_OPTION } from './constants';

export const getLeadsSourcesOptions = (leads: Enquiry[]): SelectOption[] => [
  BASE_OPTION,
  ...leads
    .map((lead) => lead.source)
    .filter((source, index, sources) => sources.indexOf(source) === index)
    .map((source) => ({
      name: source,
      value: source,
    })),
];

import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import { useFinanceOptionsContext } from 'components/FinanceOptions/context';
import FinanceOptionDivider from './Divider';
import LabelHandle from './LabelHandle';
import FinanceOptionRateDetails from './RateDetails';

export interface RateProps {
  rates: FinanceRate[];
  toggleable: boolean;
}

const Rate: React.FunctionComponent<RateProps> = function ({
  rates,
  toggleable,
}) {
  const { showDivider } = useFinanceOptionsContext();
  return (
    <>
      <LabelHandle rates={rates} toggleable={toggleable} />
      <FinanceOptionRateDetails rates={rates} />
      {showDivider && <FinanceOptionDivider />}
    </>
  );
};

export default Rate;

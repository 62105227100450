import type { JobItem } from '@kanda-libs/ks-frontend-services';
import { useMemo } from 'react';
import type { Totals } from 'types';
import { calculateJobTotal } from 'utils';

export interface LeadSingleItemHook {
  title: string;
  description: string | undefined;
  totals: Totals;
}

export default function useLeadSingleItem(item: JobItem): LeadSingleItemHook {
  const title = useMemo(() => item.title, [item]);
  const description = useMemo(() => item.description, [item]);
  const totals = useMemo(() => calculateJobTotal([item]), [item]);

  return {
    title,
    description,
    totals,
  };
}

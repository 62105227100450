import { Fixed, Percentage } from './components';

export const DEPOSIT_TYPES = {
  noDeposit: 'no_deposit',
  partialDeposit: 'partial_deposit',
  fixedDeposit: 'fixed_deposit',
};

export const TYPE_NAME = 'job.deposit_type';

export const VALUE_NAME = 'job.deposit_value.amount';

export const QUOTED_TO_NAME = 'job.quoted_to';

export const FIXED_DEPOSIT_OPTION = {
  depositType: 'fixed_deposit',
  title: 'Fixed deposit',
  children: <Fixed />,
};

export const OPTIONS = [
  {
    depositType: 'no_deposit',
    title: 'No deposit',
  },
  {
    depositType: 'partial_deposit',
    title: '% deposit',
    children: <Percentage />,
  },
  FIXED_DEPOSIT_OPTION,
];

export const FINANCE_OPTIONS_NAME = 'job.finance_options';

export const DEPOSIT_TYPE_NAME = 'job.deposit_type';

export const WARNING =
  "Some of the rates you have selected require a minimum deposit. You can check them below to see what the minimums are. If your customer chooses one of these rates, we'll let you know and you'll have to collect the deposit";

import VideoEmbed from 'components/VideoEmbed';

const FinanceEverywhereVideoEmbed: React.FunctionComponent = function ({}) {
  return (
    <VideoEmbed
      src="https://www.loom.com/embed/4e841dbb1b614e5ca62b8c00094a1388?sid=2f829e95-7302-4a56-bbe5-3a7ff1ec1f43"
      title="Finance Everywhere Link Tutorial"
    />
  );
};

export default FinanceEverywhereVideoEmbed;

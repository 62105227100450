import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';

export interface FinanceEverywhereBannerHook {
  title: string;
  buttonText: string;
  isLoading: boolean;
}

export default function useFinanceEverywhereBanner(): FinanceEverywhereBannerHook {
  const leads = useSelector(selectors.enquiry.getEntitiesAsArray);
  const leadsHasFetched = useSelector(selectors.enquiry.getHasFetched);

  const tags = useSelector(selectors.tag.getEntitiesAsArray);
  const tagsHasFetched = useSelector(selectors.tag.getHasFetched);

  const isLoading = useMemo(
    () => Boolean(!leadsHasFetched && !tagsHasFetched),
    [leadsHasFetched, tagsHasFetched],
  );

  const title = useMemo(() => {
    if (isLoading)
      return 'Create your finance everywhere link to gather leads and convert jobs';
    if (tags.length === 0)
      return 'Create your finance everywhere link to gather leads and convert jobs';
    if (leads.length === 0)
      return 'Grab your finance everywhere link to gather leads and convert jobs';
    return 'Grab your finance everywhere link to gather more leads and convert more jobs';
  }, [tags, leads, isLoading]);

  const buttonText = useMemo(() => {
    if (isLoading) return 'Get Your Shareable Link';
    if (tags.length === 0) return 'Get Your Shareable Link';
    return 'Grab Your Shareable Link';
  }, [isLoading, tags]);

  return {
    title,
    buttonText,
    isLoading,
  };
}

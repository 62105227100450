import ProgressIndicator from 'components/ProgressIndicator';
import useLeadProgressBar from './useLeadProgressBar';

const LeadProgressBar: React.FunctionComponent = function () {
  const { isLoading, steps, showBar } = useLeadProgressBar();
  if (!showBar) return null;
  return (
    <div className="flex w-full max-w-screen-lg md:-mt-20 md:mb-12 z-40 mx-auto">
      <ProgressIndicator steps={steps} isLoading={isLoading} />
    </div>
  );
};

export default LeadProgressBar;

import { useForm, type UseFormReturn } from '@kanda-libs/ks-component-ts';
import type { JobItem, WorkType } from '@kanda-libs/ks-frontend-services';
import { useLeadContext } from 'pages/Lead/context';
import { useEffect, useMemo } from 'react';

export interface LeadQuoteDetailsQuoteFormValues {
  job: {
    work_type: WorkType | undefined;
    job_items: JobItem[] | undefined;
    deposit_value: {
      amount: number | undefined;
    };
  };
}

export interface LeadQuoteDetailsQuoteFormHook {
  form: UseFormReturn<LeadQuoteDetailsQuoteFormValues>;
}

export default function useLeadQuoteDetailsQuoteForm(): LeadQuoteDetailsQuoteFormHook {
  const { lead, isLoading } = useLeadContext();

  const defaultValues = useMemo(() => {
    if (!lead) return undefined;
    return {
      job: {
        work_type: lead?.enquiry_info?.job_info?.work_type,
        job_items: lead?.enquiry_info?.job_info?.job_items,
        deposit_value: {
          amount: 0,
        },
      },
    };
  }, [lead]);

  const form = useForm<LeadQuoteDetailsQuoteFormValues>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
  });

  useEffect(() => {
    if (isLoading) return;
    form.reset(defaultValues);
  }, [form, isLoading, defaultValues]);

  return {
    form,
  };
}

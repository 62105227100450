import { useLeadContext } from 'pages/Lead/context';
import { useMemo } from 'react';
import { formatWorkTypeToString } from 'utils';

export interface LeadWorkTypeHook {
  name: string;
  valid: boolean;
  isLoading: boolean;
}

export default function useLeadWorkType(): LeadWorkTypeHook {
  const { workType, validWorkType, isLoading } = useLeadContext();

  const name = useMemo(() => {
    if (!workType) return 'Not set';
    return formatWorkTypeToString(workType);
  }, [workType]);

  return {
    name,
    valid: validWorkType,
    isLoading,
  };
}

import { Icon, Text } from '@kanda-libs/ks-design-library';
import InfoCard from 'components/InfoCard';
import InterestInFinanceTag from 'pages/Leads/components/InterestInFinanceTag';
import LeadConvertToJob from '../../LeadConvertToJob';
import LeadGoToJob from '../../LeadGoToJob';
import useLeadQuoteDetailsLead from './useLeadQuoteDetailsLead';

const LeadQuoteDetailsLead: React.FunctionComponent = function ({}) {
  const {
    iconProps,
    label,
    budget,
    workType,
    interestInFinance,
    description,
    display,
    isLoading,
  } = useLeadQuoteDetailsLead();
  return (
    <InfoCard title="Lead Details">
      <div className="flex flex-col md:px-4 lg:px-16 md:py-8 gap-y-6">
        <div className="flex flex-row md:flex-col mr-auto md:mx-auto items-center gap-y-4 gap-x-3">
          <Icon {...iconProps} isLoading={isLoading} />
          <Text
            text={label}
            isLoading={isLoading}
            className="text-16-24-em md:text-20-24-em"
          />
        </div>
        <div className="flex flex-col">
          <Text
            text="Request Work Type"
            isLoading={isLoading}
            className="text-14-22-em text-neutral-700 mb-2"
          />
          <Text
            text={workType}
            isLoading={isLoading}
            className="text-14-22 text-neutral-900"
          />
        </div>
        {description && (
          <div className="flex flex-col">
            <Text
              text="Customer Provided Description"
              isLoading={isLoading}
              className="text-14-22-em text-neutral-700 mb-2"
            />
            <Text
              text={description}
              isLoading={isLoading}
              className="text-14-22 text-neutral-900"
            />
          </div>
        )}
        <div className="flex flex-col">
          <Text
            text="Customer Budget"
            isLoading={isLoading}
            className="text-14-22-em text-neutral-700 mb-2"
          />
          <div className="flex flex-row flex-wrap gap-x-4 gap-y-1">
            <Text
              text={budget}
              className="text-16-24-em md:text-18-22-em text-neutral-700"
              isLoading={isLoading}
            />
            <InterestInFinanceTag
              interest={interestInFinance}
              isLoading={isLoading}
              className="mr-auto"
            />
          </div>
        </div>

        {display === 'convert' && (
          <div className="w-60 mx-auto mt-2 md:mt-6 mb-3">
            <LeadConvertToJob size={40} />
          </div>
        )}
        {display === 'go_to_job' && (
          <div className="w-60 mx-auto mt-2 md:mt-6 mb-3">
            <LeadGoToJob size={40} />
          </div>
        )}
      </div>
    </InfoCard>
  );
};

export default LeadQuoteDetailsLead;

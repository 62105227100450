import type { FinanceRate } from '@kanda-libs/ks-frontend-services';
import { AmountDisplay, PercentageDisplay } from '../components';
import OptionPopover from '../OptionPopover';
import useFinanceOptionUnavailable from './useFinanceOptionUnavailable';

export interface FinanceOptionRateDetailsUnavailableProps {
  rates: FinanceRate[];
}

const FinanceOptionRateDetailsUnavailable: React.FunctionComponent<FinanceOptionRateDetailsUnavailableProps> =
  function ({ rates }) {
    const {
      minDepositPercentage,
      minDepositAmount,
      maxDepositPercentage,
      maxDepositAmount,
      minJobAmount,
      maxJobAmount,
      popoverId,
      rateName,
      aprLabel,
    } = useFinanceOptionUnavailable(rates);
    return (
      <div className="flex flex-row items-center col-span-2 justify-center pr-2">
        <p className="text-12-18-em text-orange-200">Rate Unavailable</p>
        <OptionPopover popoverId={popoverId} iconStroke="orange-200">
          <div className="grid grid-cols-[1fr_auto] gap-y-2 gap-x-2">
            <p className="text-18-22-em md:text-12-18-em text-neutral-900 col-span-2 mb-2 md:mb-0">
              Rate Limits
            </p>
            <div className="flex md:hidden py-3 px-4 bg-neutral-100 text-16-20-em text-neutral-600 rounded-xl flex items-center col-span-2 mb-2 -mx-2">
              {aprLabel}
              <div className="w-1 h-1 bg-neutral-400 rounded-full mx-2" />
              {rateName}
            </div>
            <PercentageDisplay
              title="Min. deposit %"
              percentage={minDepositPercentage}
            />
            {minDepositAmount && minDepositAmount > 0 ? (
              <AmountDisplay
                title="Min. deposit value"
                amount={minDepositAmount}
              />
            ) : undefined}
            <PercentageDisplay
              title="Max. deposit %"
              percentage={maxDepositPercentage}
            />
            {maxDepositAmount && maxDepositAmount > 0 ? (
              <AmountDisplay
                title="Max. deposit value"
                amount={maxDepositAmount}
              />
            ) : undefined}
            <AmountDisplay title="Min. loan value" amount={minJobAmount} />
            <AmountDisplay title="Max. loan value" amount={maxJobAmount} />
          </div>
        </OptionPopover>
      </div>
    );
  };

export default FinanceOptionRateDetailsUnavailable;

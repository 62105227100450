import type { JobItem } from '@kanda-libs/ks-frontend-services';
import LeadSingleItem from './LeadSingleItem';

export interface LeadItemsDisplayProps {
  leadItems: JobItem[];
  isLoading: boolean;
}

const LeadItemsDisplay: React.FunctionComponent<LeadItemsDisplayProps> =
  function ({ leadItems, isLoading }) {
    if (leadItems.length === 1)
      return <LeadSingleItem item={leadItems[0]} isLoading={isLoading} />;
    return <div>f</div>;
  };

export default LeadItemsDisplay;

import FinanceOptions from 'components/FinanceOptions';
import { useFinanceRatesContext } from './context';

const FinanceRatesContent: React.FunctionComponent = function () {
  const {
    rates,
    isExample,
    lenderConfigs,
    total,
    depositAmount,
    showStoredRates,
    hideUnregulated,
    showDivider,
  } = useFinanceRatesContext();
  return (
    <FinanceOptions
      lenderConfigs={lenderConfigs}
      rates={rates}
      total={total}
      deposit={depositAmount}
      fieldName="job.finance_options"
      toggleable
      showHandle={!showStoredRates}
      hideUnregulated={hideUnregulated}
      showDivider={showDivider}
      isExample={isExample}
    />
  );
};

export default FinanceRatesContent;

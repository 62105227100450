import { Icon, Text } from '@kanda-libs/ks-design-library';
import type { Customer } from '@kanda-libs/ks-frontend-services';
import InfoCard from 'components/InfoCard';
import type { ReactNode } from 'react';
import CustomerInfoCardCustomerEmail from './CustomerEmail';
import CustomerInfoCardCustomerPhone from './CustomerPhone';
import useCustomerInfoCard from './useCustomerInfoCard';

export interface CustomerInfoCard {
  customer: Customer | undefined;
  isLoading?: boolean;
  children?: ReactNode;
}

const CustomerInfoCard: React.FunctionComponent<CustomerInfoCard> = function ({
  customer,
  isLoading = false,
  children = null,
}) {
  const { name, address, email, phone } = useCustomerInfoCard(
    customer,
    isLoading,
  );
  return (
    <InfoCard title="Homeowner" isLoading={isLoading}>
      <div className="flex flex-row gap-x-3 mb-2">
        <Icon
          icon="user"
          isLoading={isLoading}
          stroke="neutral-700"
          size={20}
          className="min-w-5 my-auto"
        />
        <Text text={name} isLoading={isLoading} className="text-14-22-em" />
      </div>
      <Text
        text={address}
        isLoading={isLoading}
        className="text-14-22 text-neutral-700 mb-2"
      />
      <CustomerInfoCardCustomerEmail email={email} isLoading={isLoading} />
      <CustomerInfoCardCustomerPhone phone={phone} isLoading={isLoading} />
      {children}
    </InfoCard>
  );
};

export default CustomerInfoCard;

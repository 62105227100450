import type { Tag } from '@kanda-libs/ks-frontend-services';
import MyAccountFinanceEverywhereLinkNewTagForm from '../NewTagForm';
import MyAccountFinanceEverywhereLinkTag from '../Tag';

export interface TagsWrapperProps {
  tags: Tag[];
  logoBase64?: string;
}

const TagsWrapper: React.FunctionComponent<TagsWrapperProps> = function ({
  tags,
  logoBase64,
}) {
  return (
    <div className="flex flex-col gap-y-4">
      {tags.map((tag, index) => (
        <MyAccountFinanceEverywhereLinkTag
          tag={tag}
          key={tag.id}
          index={index + 1}
          logoBase64={logoBase64}
        />
      ))}
      <MyAccountFinanceEverywhereLinkNewTagForm />
    </div>
  );
};

export default TagsWrapper;

import type { ProgressStep } from 'components/ProgressIndicator';
import { useLeadContext } from 'pages/Lead/context';
import { useMemo } from 'react';
import { QUOTE_STEPS, STEPS, VALID_STEPS } from './constants';

export interface LeadProgressBarHook {
  showBar: boolean;
  steps: ProgressStep[];
  isLoading: boolean;
}

export default function useLeadProgressBar(): LeadProgressBarHook {
  const { lead, archived, isLoading } = useLeadContext();

  const status = useMemo(() => {
    if (!lead) return 'viewed';
    return lead.current_state;
  }, [lead]);

  const showBar = useMemo(() => {
    if (isLoading) return true;
    if (!lead || archived) return false;
    const currentStatus = lead.current_state;
    return VALID_STEPS.includes(currentStatus);
  }, [isLoading, lead, archived]);

  const isQuote = useMemo(() => {
    if (!lead) return false;
    return Boolean(lead?.enquiry_info?.quote?.name);
  }, [lead]);

  const steps: ProgressStep[] = useMemo(() => {
    if (!isQuote)
      return STEPS.map(({ text, states }) => ({
        text,
        active: states.includes(status),
      }));
    return QUOTE_STEPS.map(({ text, states }) => ({
      text,
      active: states.includes(status),
    }));
  }, [status, isQuote]);

  return {
    showBar,
    steps,
    isLoading,
  };
}

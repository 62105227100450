import { useLeadContext } from 'pages/Lead/context';
import {
  getInterestInFinance,
  getLeadCurrentState,
  type EnquiryExtendedState,
  type InterestInFinance,
} from 'pages/Leads/helpers';
import { useMemo } from 'react';

export interface LeadNameStatusHook {
  name: string;
  currentStatus: EnquiryExtendedState | undefined;
  showStatus: boolean;
  archived: boolean;
  interestInFinance: InterestInFinance;
  isLoading: boolean;
}

export default function useLeadNameStatus(): LeadNameStatusHook {
  const { lead, archived, isLoading } = useLeadContext();

  const name = useMemo(() => {
    if (!lead) return '';
    const consumer = lead?.enquiry_info?.consumer;
    if (!consumer) return 'No name given';
    return [consumer.first_name, consumer.last_name].join(' ');
  }, [lead]);

  const currentStatus = useMemo(() => {
    if (!lead) return 'viewed';
    return getLeadCurrentState(lead);
  }, [lead]);

  const showStatus = useMemo(() => {
    if (!lead) return true;
    return Boolean(lead.current_state);
  }, [lead]);

  const interestInFinance = useMemo(
    () => getInterestInFinance(lead?.enquiry_info),
    [lead],
  );

  return {
    name,
    currentStatus,
    showStatus,
    archived,
    interestInFinance,
    isLoading,
  };
}

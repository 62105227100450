import { ModalsWrapperContext } from '@kanda-libs/ks-design-library';
import { useCallback, useContext } from 'react';
import { MODAL_ID } from './constants';

export interface LeadsFiltersModalHook {
  onClick: () => void;
}

export default function useLeadsFiltersModal(): LeadsFiltersModalHook {
  const { showModal } = useContext(ModalsWrapperContext);

  const onClick = useCallback(() => showModal(MODAL_ID), [showModal]);

  return {
    onClick,
  };
}

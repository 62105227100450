import {
  Button,
  ModalContainer,
  ModalLayoutCenter,
} from '@kanda-libs/ks-design-library';
import type { MutableRefObject } from 'react';
import TagDownloadButton from './TagDownloadButton';
import useTagDownloadModal from './useTagDownloadModal';

export interface TagDownloadModalProps {
  id: string;
  url: string;
  logoBase64: string;
  logoSize: number[];
}

const TagDownloadModal: React.FunctionComponent<TagDownloadModalProps> =
  function ({ id, url, logoBase64, logoSize }) {
    const { modalId, onClick } = useTagDownloadModal(id);
    return (
      <>
        <Button.Text
          id="qr-banner-download"
          label="Download Banner"
          className="mt-2"
          onClick={onClick}
        />
        <ModalContainer id={modalId} opacity={30}>
          {({ ref, handleClose }) => (
            <ModalLayoutCenter
              id={modalId}
              onClose={handleClose}
              ref={ref as MutableRefObject<HTMLDivElement>}
              className="w-10/12 md:w-120"
            >
              <p className="text-16-24-em text-neutral-700 mb-4">
                Download Banner
              </p>
              <p className="text-14-22 mb-4">
                Which size would you like to download?
              </p>
              <div className="flex flex-col gap-y-2">
                <TagDownloadButton
                  scale="S"
                  url={url}
                  logoBase64={logoBase64}
                  logoSize={logoSize}
                  id={id}
                />
                <TagDownloadButton
                  scale="M"
                  url={url}
                  logoBase64={logoBase64}
                  logoSize={logoSize}
                  id={id}
                />
                <TagDownloadButton
                  scale="L"
                  url={url}
                  logoBase64={logoBase64}
                  logoSize={logoSize}
                  id={id}
                />
                <TagDownloadButton
                  scale="XL"
                  url={url}
                  logoBase64={logoBase64}
                  logoSize={logoSize}
                  id={id}
                />
              </div>
            </ModalLayoutCenter>
          )}
        </ModalContainer>
      </>
    );
  };

export default TagDownloadModal;

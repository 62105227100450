import { Icon, Text } from '@kanda-libs/ks-design-library';
import useLeadWorkType from './useLeadWorkType';

const LeadWorkType: React.FunctionComponent = function ({}) {
  const { name, valid, isLoading } = useLeadWorkType();
  return (
    <div className="flex flex-col">
      <Text
        text="Work Type"
        isLoading={isLoading}
        className="text-14-22-em text-neutral-700 mb-2"
      />
      {valid ? (
        <Text
          text={name}
          isLoading={isLoading}
          className="text-14-22 text-neutral-700"
        />
      ) : (
        <div className="flex flex-row gap-x-4 px-4 py-3 border border-red-200 bg-red-100 rounded-lg">
          <Icon icon="error" size={20} stroke="red-200" />
          <Text
            text="No work type has been set - edit quote to fix this"
            isLoading={isLoading}
            className="text-14-22-em text-red-200"
          />
        </div>
      )}
    </div>
  );
};

export default LeadWorkType;

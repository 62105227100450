export interface VideoEmbedProps {
  src: string;
  title: string;
}

const VideoEmbed: React.FunctionComponent<VideoEmbedProps> = function ({
  src,
  title,
}) {
  return (
    <div className="w-full max-w-180">
      <div className="relative pb-2/3 w-full h-0 my-4">
        <iframe
          src={src}
          title={title}
          allowFullScreen
          className="absolute top-0 left-0 w-full h-full border-0"
        />
      </div>
    </div>
  );
};

export default VideoEmbed;

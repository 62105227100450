import { useMemo } from 'react';

import { SKELETON_ITEMS } from './List-constants';

import useCurrentJob from '../../../../JobDetails-useCurrentJob';

const ContainerComponent = ({ children }) => {
  const { job, isLoading } = useCurrentJob();

  const lineItems = useMemo(() => {
    if (isLoading) return SKELETON_ITEMS;
    return (
      job?.job_items.map((item, index) => ({
        key: `item-${index}`,
        ...item,
      })) || []
    );
  }, [isLoading, job]);

  return children({
    lineItems,
    isLoading,
  });
};

ContainerComponent.displayName = 'Job-Items-List-container';

export default ContainerComponent;

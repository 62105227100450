import { DesktopLayout } from 'pages/MyAccount/components';
import MyAccountFinanceEverywhereLinkContent from '../Content';

const MyAccountFinanceEverywhereLinkDesktop: React.FunctionComponent =
  function () {
    return (
      <DesktopLayout>
        <MyAccountFinanceEverywhereLinkContent />
      </DesktopLayout>
    );
  };

export default MyAccountFinanceEverywhereLinkDesktop;

import { URLS } from '../../config/application';

export const CLASS_NAMES = {
  container: 'flex flex-1 bg-neutral-000 -ml-6 -mr-6 -mb-14 relative',
  content: 'w-full px-6 py-10',
};

export const MENU_ITEMS = [
  {
    name: 'Account Settings',
    description: 'Manage your login details',
    to: URLS.accountSettings,
  },
  {
    name: 'Finance Everywhere Link',
    description: 'Manage your finance everywhere link',
    to: URLS.financeEverywhereLink,
  },
];

export const ADMIN_ITEMS = [
  {
    name: 'Company Information',
    description: 'Manage your business information',
    to: URLS.companyInformation,
  },
  {
    name: 'Job Settings',
    description: 'Manage your finance and quote defaults',
    to: URLS.jobSettings,
  },
  {
    name: 'Finance Rates',
    description: 'Manage and request finance rates',
    to: URLS.financeRates,
  },
  {
    name: 'Subscription',
    description: 'Manage your subscription & billing',
    to: URLS.subscription,
  },
  {
    name: 'Team Settings',
    description: 'Manage your team settings',
    to: URLS.teamSettings,
  },
  {
    name: 'Notification Preferences',
    description: "Manage your user's notifications",
    to: URLS.notificationPreferences,
  },
];

export const MOBILE_ADMIN_ITEMS = [
  {
    name: 'Company Information',
    description: 'Manage your business information',
    to: URLS.companyInformation,
  },
  {
    name: 'Job Settings',
    description: 'Manage your finance and quote defaults',
    to: URLS.jobSettings,
  },
  {
    name: 'Finance Rates',
    description: 'Manage and request finance rates',
    to: URLS.financeRates,
  },
  {
    name: 'Subscription',
    description: 'Manage your subscription & billing',
    to: URLS.subscription,
  },
  {
    name: 'Team Settings',
    description: 'Manage your team settings',
    to: URLS.teamSettings,
  },
];

export const VERIFICATION_ITEM = {
  name: 'Director Verification',
  description: 'Manage director identity verficiation',
  badgeColor: 'orange',
  to: URLS.directorVerification,
};

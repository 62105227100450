import {
  ModalContainer,
  ModalLayoutSlideUp,
} from '@kanda-libs/ks-design-library';
import type { MutableRefObject } from 'react';
import LeadsFilters from '../../LeadsFilters';
import { MODAL_ID } from '../constants';

const LeadsFitersModalModal: React.FunctionComponent = function ({}) {
  return (
    <ModalContainer id={MODAL_ID}>
      {({ id, handleClose, ref }) => (
        <ModalLayoutSlideUp
          id={id}
          onClose={handleClose}
          ref={ref as MutableRefObject<HTMLDivElement>}
          variant="MARKETING"
        >
          <div className="overflow-scroll w-full">
            <p className="text-16-24-em text-neutral-700 mb-4">Filters</p>
            <LeadsFilters />
          </div>
        </ModalLayoutSlideUp>
      )}
    </ModalContainer>
  );
};

export default LeadsFitersModalModal;

import {
  useIsDesktop,
  useToast,
  type PopoverMenuItemProps,
  type PopoverMenuItemSize,
} from '@kanda-libs/ks-design-library';
import { actions } from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import useApiError from 'hooks/useApiError';
import { useLeadContext } from 'pages/Lead/context';
import { useCallback, useMemo } from 'react';

export interface LeadActionsMenuHook {
  items: PopoverMenuItemProps[];
  size: PopoverMenuItemSize;
  isSubmitting: boolean;
}

export default function useLeadActionsMenu(
  callback?: () => void,
): LeadActionsMenuHook {
  const { id, isSubmitting, setIsSubmitting, archived } = useLeadContext();
  const dispatch = useAppDispatch();
  const onApiError = useApiError('Unknown error archiving lead');
  const { showSuccess } = useToast();
  const isDesktop = useIsDesktop();

  const onArchiveClick = useCallback(() => {
    if (!id) return;
    setIsSubmitting(true);
    dispatch(
      actions.postEnquiryArchive({
        params: { id },
        onError: (err) => {
          setIsSubmitting(false);
          onApiError(err);
          if (callback) callback();
        },
        onSuccess: () => {
          setIsSubmitting(false);
          showSuccess('Lead archived');
          if (callback) callback();
        },
      }),
    );
  }, [id, dispatch, onApiError, callback, showSuccess, setIsSubmitting]);

  const onUnarchiveClick = useCallback(() => {
    if (!id) return;
    setIsSubmitting(true);
    dispatch(
      actions.postEnquiryUnarchive({
        params: { id },
        onError: (err) => {
          setIsSubmitting(false);
          onApiError(err);
          if (callback) callback();
        },
        onSuccess: () => {
          setIsSubmitting(false);
          showSuccess('Lead unarchived');
          if (callback) callback();
        },
      }),
    );
  }, [id, dispatch, onApiError, callback, showSuccess, setIsSubmitting]);

  const items = useMemo(
    () => [
      ...(archived
        ? [
            {
              onClick: onUnarchiveClick,
              icon: 'archive',
              name: 'Unarchive Lead',
              disabled: isSubmitting,
            },
          ]
        : [
            {
              onClick: onArchiveClick,
              icon: 'archive',
              name: 'Archive Lead',
              disabled: isSubmitting,
            },
          ]),
    ],
    [archived, onArchiveClick, onUnarchiveClick, isSubmitting],
  );

  const size = useMemo(() => (isDesktop ? 'small' : 'big'), [isDesktop]);

  return {
    items,
    size,
    isSubmitting,
  };
}

import type { JobItem } from '@kanda-libs/ks-frontend-services';

export const PLACEHOLDER_ITEMS: JobItem[] = [
  {
    title: 'No job title extracted',
    description:
      "We couldn't extract any information from this quote - please edit to send it",
    quantity: 100,
    price: {
      amount: 0,
      currency: 'GBP',
    },
    vat_rate: 'vat_not_set',
    vat: {
      amount: 0,
      currency: 'GBP',
    },
  },
];

import type { TagRenderSize } from '.';

export const DEFAULT_SIZES = {
  size: 150,
  quietZone: 10,
  eyeRadius: 5,
  logoPadding: 2,
  logoScaleSize: 50,
  kandaLogoWidth: 100,
  kandaLogoHeight: 30,
  border: 1,
  padding: 12,
  width: 440,
  height: 196,
  textScanHeight: 24,
  textScanLineHeight: 28,
  textFinanceHeight: 14,
  textFinanceLineHeight: 22,
  marginTop: 20,
};

export const SCALES: Record<TagRenderSize, number> = {
  S: 1,
  M: 2,
  L: 4,
  XL: 8,
};

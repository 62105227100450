import FinanceRatesContent from './Content';
import FinanceRatesProvider from './context';

export interface FinanceRatesProps {
  showStoredRates?: boolean;
  hideUnregulated?: boolean;
  showDivider?: boolean;
}

const FinanceRates: React.FunctionComponent<FinanceRatesProps> = function ({
  showStoredRates = false,
  hideUnregulated = false,
  showDivider = true,
}) {
  return (
    <FinanceRatesProvider
      showStoredRates={showStoredRates}
      hideUnregulated={hideUnregulated}
      showDivider={showDivider}
    >
      <FinanceRatesContent />
    </FinanceRatesProvider>
  );
};

export default FinanceRates;

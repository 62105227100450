import { Button, type ButtonTextProps } from '@kanda-libs/ks-design-library';
import useLeadConvertToJob from './useLeadConvertToJob';

export interface LeadConvertToJobProps {
  size?: ButtonTextProps['size'];
}

const LeadConvertToJob: React.FunctionComponent<LeadConvertToJobProps> =
  function ({ size = 48 }) {
    const { onClick, isSubmitting } = useLeadConvertToJob();
    return (
      <Button.Text
        id="lead-convert-to-job"
        size={size}
        onClick={onClick}
        disabled={isSubmitting}
        icon="check"
        label="Convert Lead to Job"
        className="w-full"
      />
    );
  };

export default LeadConvertToJob;

import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type RefObject,
} from 'react';

export interface TagBannerHook {
  imageRef: RefObject<HTMLImageElement>;
  size: number[] | undefined;
}

export default function useTagBanner(url: string, id: string): TagBannerHook {
  const [size, setSize] = useState<number[] | undefined>(undefined);

  const calculateImageSizes = useCallback((element: HTMLImageElement) => {
    const width = element.naturalWidth;
    const height = element.naturalHeight;
    if (width === height) {
      setSize([50, 50]);
      return;
    }
    if (width > height) {
      setSize([50, Math.round((height * 50) / width)]);
      return;
    }
    setSize([Math.round((width * 50) / height), 50]);
  }, []);

  const imageRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    const imageElemenet = imageRef.current;
    if (!imageElemenet || !url || !id) return;
    setTimeout(() => calculateImageSizes(imageElemenet), 10);
  }, [url, id, calculateImageSizes]);

  return {
    imageRef,
    size,
  };
}

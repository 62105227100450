export const isBlackFriday = () => {
  const now = new Date();
  const month = now.getMonth() + 1;
  return month === 11;
};

export const formatDateToDateString = (date: Date): string => {
  const day = `0${date.getDate()}`.slice(-2);
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const year = date.getFullYear();
  return [day, month, year].join('/');
};

import { Layout } from '@kanda-libs/ks-design-library';
import Header from '../../components/Header';
import MyAccountFinanceEverywhereLinkContent from '../Content';

const MyAccountFinanceEverywhereLinkMobile: React.FunctionComponent =
  function () {
    return (
      <Layout noBorder header={<Header />}>
        <MyAccountFinanceEverywhereLinkContent />
      </Layout>
    );
  };

export default MyAccountFinanceEverywhereLinkMobile;

import { toPng } from 'html-to-image';
import { useCallback, useMemo, useRef, type RefObject } from 'react';
import type { TagRenderSize } from '../../TagRender';
import { DEFAULT_SIZES, SCALES } from '../../TagRender/constants';
import { SIZES } from './constants';

const handleRefToPng = async (
  element: HTMLDivElement,
): Promise<string | undefined> => {
  const dataUrl = await toPng(element, { cacheBust: false });
  if (!dataUrl) return undefined;
  return dataUrl;
};

export interface TagDownloadButtonHook {
  size: string;
  width: number;
  height: number;
  onClick: () => void;
  ref: RefObject<HTMLDivElement>;
}

export default function useTagDownloadButton(
  scale: TagRenderSize,
  id: string,
): TagDownloadButtonHook {
  const size = useMemo(() => SIZES[scale], [scale]);

  const proportion = useMemo(() => SCALES[scale], [scale]);

  const width = useMemo(() => DEFAULT_SIZES.width * proportion, [proportion]);

  const height = useMemo(() => DEFAULT_SIZES.height * proportion, [proportion]);

  const ref = useRef<HTMLDivElement>(null);

  const onClick = useCallback(async () => {
    if (!ref.current) return;
    const element = ref.current;
    const dataUrl = await handleRefToPng(element);
    if (!dataUrl) return;
    const link = document.createElement('a');
    link.download = `kanda-banner-link-${id}-${size
      .toLocaleLowerCase()
      .replace(/ /g, '-')}.png`;
    link.href = dataUrl;
    link.click();
  }, [id, size]);

  return {
    size,
    width,
    height,
    onClick,
    ref,
  };
}

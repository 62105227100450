import { SkeletonLoader, Text } from '@kanda-libs/ks-design-library';
import { useMyAccountFinanceEverywhereLinkContext } from '../../context';
import TagsWrapper from '../TagWrapper';

const MyAccountFinanceEverywhereLinkTags: React.FunctionComponent =
  function () {
    const { tags, isLoading, logoBase64 } =
      useMyAccountFinanceEverywhereLinkContext();
    return (
      <div className="flex flex-col mb-6">
        <Text
          text="Your Links"
          className="text-16-20-em text-neutral-900 mb-4"
        />
        <SkeletonLoader
          isLoading={isLoading}
          height={96}
          count={2}
          className="mt-4 first:mt-0"
          afterLoading={<TagsWrapper tags={tags} logoBase64={logoBase64} />}
        />
      </div>
    );
  };

export default MyAccountFinanceEverywhereLinkTags;

import { useMemo } from 'react';
import type { TagRenderSize } from '.';
import { DEFAULT_SIZES, SCALES } from './constants';

export interface TagRenderHook {
  size: number;
  quietZone: number;
  eyeRadius: number;
  logoPadding: number;
  logoWidth: number;
  logoHeight: number;
  kandaLogoWidth: number;
  kandaLogoHeight: number;
  width: number;
  height: number;
  textScanHeight: number;
  textScanLineHeight: number;
  textFinanceHeight: number;
  textFinanceLineHeight: number;
  border: number;
  padding: number;
  marginTop: number;
}

export default function useTagRender(
  logoSize: number[],
  scale: TagRenderSize,
): TagRenderHook {
  const proportion = useMemo(() => SCALES[scale], [scale]);

  const size = useMemo(() => DEFAULT_SIZES.size * proportion, [proportion]);

  const quietZone = useMemo(
    () => DEFAULT_SIZES.quietZone * proportion,
    [proportion],
  );

  const eyeRadius = useMemo(
    () => DEFAULT_SIZES.eyeRadius * proportion,
    [proportion],
  );

  const logoPadding = useMemo(
    () => DEFAULT_SIZES.logoPadding * proportion,
    [proportion],
  );

  const logoWidth = useMemo(
    () => logoSize[0] * proportion,
    [logoSize, proportion],
  );

  const logoHeight = useMemo(
    () => logoSize[1] * proportion,
    [logoSize, proportion],
  );

  const kandaLogoWidth = useMemo(
    () => DEFAULT_SIZES.kandaLogoWidth * proportion,
    [proportion],
  );

  const kandaLogoHeight = useMemo(
    () => DEFAULT_SIZES.kandaLogoHeight * proportion,
    [proportion],
  );

  const width = useMemo(() => DEFAULT_SIZES.width * proportion, [proportion]);

  const height = useMemo(() => DEFAULT_SIZES.height * proportion, [proportion]);

  const textScanHeight = useMemo(
    () => DEFAULT_SIZES.textScanHeight * proportion,
    [proportion],
  );

  const textScanLineHeight = useMemo(
    () => DEFAULT_SIZES.textScanLineHeight * proportion,
    [proportion],
  );

  const textFinanceHeight = useMemo(
    () => DEFAULT_SIZES.textFinanceHeight * proportion,
    [proportion],
  );

  const textFinanceLineHeight = useMemo(
    () => DEFAULT_SIZES.textFinanceLineHeight * proportion,
    [proportion],
  );

  const border = useMemo(() => DEFAULT_SIZES.border * proportion, [proportion]);

  const padding = useMemo(
    () => DEFAULT_SIZES.padding * proportion,
    [proportion],
  );

  const marginTop = useMemo(
    () => DEFAULT_SIZES.marginTop * proportion,
    [proportion],
  );

  return {
    size,
    quietZone,
    eyeRadius,
    logoPadding,
    logoWidth,
    logoHeight,
    kandaLogoWidth,
    kandaLogoHeight,
    width,
    height,
    textScanHeight,
    textScanLineHeight,
    textFinanceHeight,
    textFinanceLineHeight,
    border,
    padding,
    marginTop,
  };
}

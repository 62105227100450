export interface CardProps {
  children: React.ReactNode;
  title: string;
  subtitle: string;
}

const Card: React.FunctionComponent<CardProps> = function ({
  children,
  title,
  subtitle,
}) {
  return (
    <div className="flex flex-col md:px-4 md:pt-5 pb-0 md:pb-3 border-0 md:border border-neutral-300 rounded-lg md:shadow-none">
      <h2 className="text-14-22-em text-neutral-700 mb-0 mb-1">{title}</h2>
      <h3 className="text-12-18 text-neutral-600 mb-4">{subtitle}</h3>
      {children}
    </div>
  );
};

export default Card;

import LeadCustomer from 'pages/Lead/components/LeadCustomer';
import LeadPreferences from 'pages/Lead/components/LeadPreferences';
import LeadProgressBar from 'pages/Lead/components/LeadProgressBar';
import LeadQuoteDetails from 'pages/Lead/components/LeadQuoteDetails';

const LeadDesktopContent: React.FunctionComponent = function () {
  return (
    <div className="flex flex-col flex-1 -mt-8">
      <LeadProgressBar />
      <div className="grid grid-cols-[2fr_1fr] gap-x-8 lg:gap-x-18 w-full max-w-screen-lg mx-auto">
        <LeadQuoteDetails />
        <div className="flex flex-col gap-y-6">
          <LeadCustomer />
          <LeadPreferences />
        </div>
      </div>
    </div>
  );
};

export default LeadDesktopContent;

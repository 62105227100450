import { URLS } from 'config';
import { useLeadContext } from 'pages/Lead/context';
import { useCallback } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

export interface LeadGoToJobHook {
  onClick: () => void;
}

export default function useLeadGoToJob(): LeadGoToJobHook {
  const { id } = useLeadContext();
  const { push } = useHistory();

  const onClick = useCallback(() => {
    if (!id) return;
    push(generatePath(URLS.job, { id }));
  }, [id, push]);

  return {
    onClick,
  };
}

import { Button } from '@kanda-libs/ks-design-library';
import LeadsFitersModalModal from './Modal';
import useLeadsFiltersModal from './useLeadsFiltersModal';

const LeadsFiltersModal: React.FunctionComponent = function ({}) {
  const { onClick } = useLeadsFiltersModal();
  return (
    <>
      <Button.Icon
        id="leads-table-filter-mobile"
        icon="filter"
        variant="light-turquoise"
        onClick={onClick}
      />
      <LeadsFitersModalModal />
    </>
  );
};

export default LeadsFiltersModal;

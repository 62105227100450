import { URLS } from 'config';
import { Link } from 'react-router-dom';
import { scrollToTop } from 'utils';
import { type WrapperProps } from './useLeadCard';

const LinkWrapper: React.FunctionComponent<WrapperProps> = function ({
  children,
  className,
  to,
}) {
  return (
    <Link
      to={to || URLS.home}
      className={className}
      onClick={() => scrollToTop()}
    >
      {children}
    </Link>
  );
};

export default LinkWrapper;

import { Text, useIsDesktop } from '@kanda-libs/ks-design-library';
import { PaymentAndFinanceOptions } from 'pages/CreateJob/components';

export interface LeadItemsFinanceProps {
  isLoading?: boolean;
}

const LeadItemsFinance: React.FunctionComponent<LeadItemsFinanceProps> =
  function ({ isLoading = false }) {
    const isDesktop = useIsDesktop();
    return (
      <div className="flex flex-col">
        <Text
          text="Current Finance Rates"
          isLoading={isLoading}
          className="text-14-22-em text-neutral-700 mb-4"
        />
        <PaymentAndFinanceOptions.FinanceRates
          hideUnregulated
          showStoredRates
          showDivider={isDesktop}
        />
      </div>
    );
  };

export default LeadItemsFinance;

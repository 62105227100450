import { useFormContext, useWatch } from '@kanda-libs/ks-component-ts';
import type { FinanceRate, JobItem } from '@kanda-libs/ks-frontend-services';
import useCreateJobContextValue from 'pages/CreateJob/CreateJob-useCreateJobContextValue';
import useIsLeadJob from 'pages/CreateJob/CreateJob-useIsLeadJob';
import { useCallback, useMemo, useState } from 'react';

import { pipe } from 'fp-ts/lib/function';
import { useCurrentCompany } from 'hooks';
import {
  calculateJobTotal,
  filterForUnregulatedRates,
  getCompanyInitialRates,
  getFinanceRateNameKey,
} from '../../../../../utils';
import {
  DEPOSIT_TYPES,
  FINANCE_OPTIONS_NAME,
  FIXED_DEPOSIT_OPTION,
  OPTIONS,
  TYPE_NAME,
  VALUE_NAME,
} from './Fields-constants';

export interface ContainerComponentChildrenArgs {
  isLoading: boolean;
  onClick: (depositType: string) => void;
  financeOptionsError: boolean;
  options?: {
    depositType: string;
    title: string;
    children?: JSX.Element;
  }[];
}

export interface ContainerComponentProps {
  children: (args: ContainerComponentChildrenArgs) => JSX.Element;
}

const ContainerComponent = ({ children }: ContainerComponentProps) => {
  const { data } = useCreateJobContextValue();
  const isLeadJob = useIsLeadJob();
  const { company } = useCurrentCompany();

  const isLoading = data?.isLoading || false;
  const jobType = data?.job?.job_type;

  const [financeOptionsError, setFinanceOptionsError] = useState(false);

  const financeOptions: FinanceRate[] = useWatch({
    name: FINANCE_OPTIONS_NAME,
  });

  const { setValue } = useFormContext();

  const [lineItems]: [JobItem[]] = useWatch({
    name: ['job.job_items'],
  });

  const totals = calculateJobTotal(lineItems);

  const options = useMemo(() => {
    // Lead jobs should only have fixed deposit option
    if (isLeadJob) return [FIXED_DEPOSIT_OPTION];
    if (!jobType || jobType === 'standard') return OPTIONS;
    return OPTIONS.slice(1);
  }, [jobType, isLeadJob]);

  const onClick = useCallback(
    (depositType: string) => {
      setValue(TYPE_NAME, depositType);
      if (depositType === DEPOSIT_TYPES.noDeposit) {
        setValue(VALUE_NAME, 0);
        if (financeOptions.length === 0) return;
        const companyRates = company?.finance_rates;
        if (!companyRates) return;
        const formattedCompanyRates = pipe(
          companyRates,
          getCompanyInitialRates,
          filterForUnregulatedRates,
        );
        const formattedFinanceOptions = (
          financeOptions as unknown as string[]
        ).map((rateFullKey) => rateFullKey.split('-').slice(0, 2).join('-'));
        const selectedRates = formattedCompanyRates.filter((rate) =>
          formattedFinanceOptions.includes(getFinanceRateNameKey(rate)),
        );
        if (selectedRates.length === 0) return;
        const nonZeroMins = selectedRates
          .map((rate) => rate.min_deposit_pct || 0)
          .some((rate) => rate > 0);
        if (!nonZeroMins) return;
        setFinanceOptionsError(true);
        return;
      }
      setFinanceOptionsError(false);
      setValue(VALUE_NAME, Math.floor(totals.totalIncVat * 0.1));
    },
    [company, financeOptions, setValue, totals.totalIncVat],
  );

  return children({
    isLoading,
    onClick,
    financeOptionsError,
    options,
  });
};

ContainerComponent.displayName = 'CreateJob-Desktop-Deposit-Info-container';

export default ContainerComponent;

import LeadQuoteDetailsLead from './Lead';
import LeadQuoteDetailsQuote from './Quote';
import useLeadQuoteDetails from './useLeadQuoteDetails';

const LeadQuoteDetails: React.FunctionComponent = function ({}) {
  const { show } = useLeadQuoteDetails();
  if (show === 'quote') return <LeadQuoteDetailsQuote />;
  return <LeadQuoteDetailsLead />;
};

export default LeadQuoteDetails;

import type {
  ButtonIconVariant,
  ButtonTextVariant,
} from '@kanda-libs/ks-design-library';

export const CLASS_NAMES = {
  container: 'flex flex-col z-40 -mt-8 md:-mt-2',
  header: 'flex flex-row justify-between mb-2 md:mb-6 items-center',
  options: 'flex flex-row',
  info: 'flex flex-row md:mb-2 border border-neutral-300 rounded-lg md:border-0',
  title: 'text-style-b text-neutral-900 mr-auto',
};

export const BANNER_TYPE = {
  LOADING: 'loading',
  EMPTY: 'empty',
  FILTER: 'filter',
};

export const BANNER_CLASS_NAMES = {
  container:
    'flex flex-col border-r w-1/2 p-3 last:border-0 md:p-4 md:w-1/3 md:border border-neutral-300 md:rounded-lg md:mr-4 md:last:border',
  title: 'text-style-h text-neutral-600 mb-1 md:text-style-g',
  text: 'text-style-e text-neutral-900 md:text-style-d',
};

export const BANNER_SKELETONS = {
  title: {
    width: 120,
    height: 16,
  },
  text: {
    width: 90,
    height: 20,
  },
};

export const BUTTON_PROPS = {
  mobile: {
    variant: 'solid-grey' as ButtonIconVariant,
    icon: 'archive',
    size: '40-20' as unknown as number,
    id: 'jobs-banner-archive',
  },
  desktop: {
    variant: 'outline' as ButtonTextVariant,
    size: 40,
    id: 'jobs-banner-archive',
    text: 'Archive',
    label: 'View archive',
    className: 'whitespace-nowrap',
  },
};

import NewTagFormButton from './Button';
import NewTagFormForm from './Form';
import useNewTagFormButton from './useNewTagFormButton';

const MyAccountFinanceEverywhereLinkNewTagForm: React.FunctionComponent =
  function () {
    const { showForm, onAddClick, onCancelClick } = useNewTagFormButton();
    if (showForm) return <NewTagFormForm onCancelClick={onCancelClick} />;
    return <NewTagFormButton onAddClick={onAddClick} />;
  };

export default MyAccountFinanceEverywhereLinkNewTagForm;

import type { PreferenceMap } from '@kanda-libs/ks-frontend-services';
import { useLeadContext } from 'pages/Lead/context';
import { useMemo } from 'react';

export interface LeadPreferencesHook {
  preferences: PreferenceMap | undefined;
  dateRequested: Date | undefined;
  callback: string | undefined;
  timeframe: string | undefined;
  hasQuote: boolean;
  isLoading: boolean;
}

export default function useLeadPreferences(): LeadPreferencesHook {
  const { lead, isLoading } = useLeadContext();

  const hasQuote = useMemo(() => {
    if (isLoading) return false;
    return Boolean(lead?.enquiry_info?.quote);
  }, [isLoading, lead]);

  const preferences = useMemo(() => {
    if (isLoading) return undefined;
    return lead?.enquiry_info?.consumer_preferences;
  }, [isLoading, lead]);

  const callback = useMemo(() => preferences?.callback, [preferences]);

  const timeframe = useMemo(() => preferences?.timeframe, [preferences]);

  const dateRequested = useMemo(() => {
    if (isLoading) return undefined;
    const created = lead?.metadata?.created_at;
    if (!created) return undefined;
    return new Date(created);
  }, [isLoading, lead]);

  return {
    preferences,
    dateRequested,
    callback,
    timeframe,
    hasQuote,
    isLoading,
  };
}

import { Form } from '@kanda-libs/ks-component-ts';
import LeadQuoteDetailsQuoteContent from './Content';
import useLeadQuoteDetailsQuoteForm from './useLeadQuoteDetailsQuoteForm';

const LeadQuoteDetailsQuote: React.FunctionComponent = function () {
  const { form } = useLeadQuoteDetailsQuoteForm();
  return (
    <Form form={form} onSubmit={() => {}} id="lead-details-finance">
      <LeadQuoteDetailsQuoteContent />
    </Form>
  );
};

export default LeadQuoteDetailsQuote;

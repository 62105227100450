import { pipe } from 'fp-ts/lib/function';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import {
  getActiveLeads,
  getNonArchivedLeads,
  getNonConvertedLeads,
} from 'utils/Leads-functions';
import { HEADING } from './constants';

export interface LeadsActionBannerHook {
  title?: string;
  isLoading: boolean;
}

export default function useLeadsActionBanner(): LeadsActionBannerHook {
  const leads = useSelector(selectors.enquiry.getEntitiesAsArray);
  const leadsHasFetched = useSelector(selectors.enquiry.getHasFetched);

  const isLoading = useMemo(() => !leadsHasFetched, [leadsHasFetched]);

  const title = useMemo(() => {
    if (isLoading) return undefined;
    if (leads.length === 0) return undefined;
    const openLeads = pipe(
      leads,
      getNonArchivedLeads,
      getActiveLeads,
      getNonConvertedLeads,
    );
    // const validLeads = leads.filter((lead) => lead.archived === 'no');
    if (openLeads.length === 0) return undefined;
    return HEADING.replace(/{{num}}/g, String(openLeads.length));
  }, [leads, isLoading]);

  return {
    title,
    isLoading,
  };
}

import { Button, type ButtonTextProps } from '@kanda-libs/ks-design-library';
import useLeadConfirmDetails from './useLeadConfirmDetails';

export interface LeadConfirmDetailsProps {
  size?: ButtonTextProps['size'];
}

const LeadConfirmDetails: React.FunctionComponent<LeadConfirmDetailsProps> =
  function ({ size = 48 }) {
    const { validWorkType, onClick, isSubmitting } = useLeadConfirmDetails();
    if (!validWorkType) return null;
    return (
      <Button.Text
        id="lead-confirm-details"
        size={size}
        onClick={onClick}
        disabled={isSubmitting}
        icon="check"
        label="Confirm Job"
        className="w-full md:whitespace-nowrap"
      />
    );
  };

export default LeadConfirmDetails;

import type { StringIndexedObject } from '@kanda-libs/ks-design-library';
import {
  FinanceProvider,
  WorkType,
  type FinanceRate,
  type FinanceType,
  type InfoWorkType,
  type Job,
} from '@kanda-libs/ks-frontend-services';

import { BUY_NOW_PAY_LATER_APR_TYPE } from 'constants/finance';
import { map } from 'fp-ts/Array';
import { flow, pipe } from 'fp-ts/lib/function';
import { groupBy } from 'fp-ts/NonEmptyArray';
import {
  financeRateIsSelectable,
  financeRateIsWithinBounds,
} from 'pages/CreateJob/components/PaymentAndFinanceOptions/FinanceOptions/components/SlimOption/helpers';

import { getFinanceOptionDetails } from './Finance-functions';
import { calculateJobTotal } from './Jobs-functions';
import { pluralise } from './String-functions';

export const rateIsInterestFree = (rate: FinanceRate) =>
  rate.name.includes('_FREE');

export const rateIsBuyNowPayLater = (rate: FinanceRate) =>
  rate.apr_type === BUY_NOW_PAY_LATER_APR_TYPE;

export const rateIsUnregulated = (rate: FinanceRate) =>
  rate.apr_type === 'INTEREST_FREE' && (rate?.duration || 12) <= 10;

export const ratesIncludesBuyNowPayLater = (rates: FinanceRate[]): boolean =>
  pipe(
    rates,
    map(rateIsBuyNowPayLater),
    (value) => value.filter(Boolean).length > 0,
  );

const eitherUnitShouldUseMonths = (min: number, max: number | null) =>
  min < 12 || (max !== null && max < 12);

const eitherUnitYearIsNotWholeNumber = (min: number, max: number | null) =>
  min % 12 !== 0 || (max !== null && max % 12 !== 0);

const getLabelUnit = (
  minMonths: number,
  maxMonths: number | null,
): 'month' | 'year' => {
  if (eitherUnitShouldUseMonths(minMonths, maxMonths)) return 'month';
  if (eitherUnitYearIsNotWholeNumber(minMonths, maxMonths)) return 'month';
  return 'year';
};

export const getFormattedUnit = (
  value: number,
  unit: 'month' | 'year',
): number => (unit === 'month' ? value : value / 12);

export const getLabelValue = (min: number, max: number): string =>
  min === max ? min.toString() : [min, max].filter(Boolean).join('-');

export const getLabel = ([min, max]: [number, number | null]): string =>
  pipe(
    [min, max] as [number, number | null],
    ([currentMin, currentMax]) =>
      [currentMin, currentMax, getLabelUnit(currentMin, currentMax)] as [
        number,
        number,
        'month' | 'year',
      ],
    ([currentMin, currentMax, unit]) =>
      [
        getFormattedUnit(currentMin, unit),
        getFormattedUnit(currentMax, unit),
        unit,
      ] as [number, number, 'month' | 'year'],
    ([currentMin, currentMax, unit]) => [
      getLabelValue(currentMin, currentMax),
      pluralise(currentMin, unit, `${unit}s`),
    ],
    (value) => value.join(' '),
  );

export const getFinanceRateInterestRate = (rate: FinanceRate): number => {
  if (rateIsInterestFree(rate)) {
    return 0;
  }

  return Number(rate.name.split('_').pop());
};

export const getFinanceRateDuration = (rate: FinanceRate): number => {
  if (rate?.duration) return rate.duration;
  return Number(rate.name.split('_', 3).pop());
};

export interface FinanceRateDetails {
  key: string;
  apr: number;
  duration: number;
  financeTypes: FinanceType[];
}

export const getFinanceRateKey = (rate: FinanceRate): string => {
  const interestRate = getFinanceRateInterestRate(rate);
  const duration = getFinanceRateDuration(rate);
  const { provider, fee, charge } = rate;

  return [interestRate, duration, provider, fee, charge].join('-');
};

export const getFinanceRateDetails = (
  rate: FinanceRate,
): FinanceRateDetails => ({
  key: getFinanceRateKey(rate),
  apr: getFinanceRateInterestRate(rate),
  duration: getFinanceRateDuration(rate),
  financeTypes: rate.finance_types,
});

export const getMinMaxDurations = (durations: number[]): [number, number] =>
  pipe(durations, (value) => [Math.min(...value), Math.max(...value)]);
export interface DurationUnits {
  value: number;
  units: string;
}

export const getDurationMinMax = (durations: number[]): [number, number] =>
  pipe(durations, (value) => [Math.min(...value), Math.max(...value)]);

// If the duration in years, divided by 12 is not a whole number, return years
// otherwise return months. This is so we don't display 18 months as 1.5 years
export const getDurationMonthsAsWholeNumberOrYears = (
  durationMonths: number,
): number => (durationMonths % 12 === 0 ? durationMonths : durationMonths * 12);

export const getFormattedMinMaxDuration = ([min, max]: [number, number]): [
  number,
  number,
] => {
  const formattedMin = min < 12 ? min : min / 12;
  const formattedMax = min < 12 ? max : max / 12;

  return pipe(
    [formattedMin, formattedMax],
    (value) =>
      value.map(getDurationMonthsAsWholeNumberOrYears) as [number, number],
  );
};

export const getDurationMinMaxLabel = (durations: [number, number]): string =>
  pipe(
    durations,
    getFormattedMinMaxDuration,
    ([min, max]) =>
      [min, min, getLabel([min, max])] as [number, number, string],
    ([min, max, units]) => [[min, max].join('-'), units].join(' '),
  );

export const getCombinedFinanceRatesDurations = map(getFinanceRateDuration);

export const getBnplLabel = (rates: FinanceRate[], label: string): string => {
  if (!rates.some((rate) => rate?.apr_type === 'BUYNOW_PAYLATER')) return label;
  return `BNPL ${label}`;
};

export const getCombinedFinanceRatesDurationLabel = (
  rates: FinanceRate[],
): string =>
  pipe(
    rates,
    getCombinedFinanceRatesDurations,
    getDurationMinMax,
    getLabel,
    (label) => getBnplLabel(rates, label),
  );

export const getBuyNowPayLaterRateLabel = (
  rate: FinanceRate,
  hasPrefix = false,
): string =>
  [
    hasPrefix ? 'BNPL' : 'BNPL',
    [rate.deferred_duration, rate.duration].join(' + '),
    'months',
  ]
    .filter(Boolean)
    .join(' ');

export const getFinanceRateLabel = (rate: FinanceRate): string =>
  pipe(
    rate,
    (currentRate) =>
      [currentRate, rateIsBuyNowPayLater(rate)] as [FinanceRate, boolean],
    ([currentRate, isBuyNowPayLater]) => {
      if (isBuyNowPayLater) {
        return getBuyNowPayLaterRateLabel(currentRate);
      }

      const { duration } = getFinanceOptionDetails(currentRate);

      return getLabel([duration, null]);
    },
  );

export const getFinanceRateGroupName = (
  { apr, financeTypes }: FinanceRateDetails,
  indictateSecondLine = false,
  suppressInterestLabel = false,
): string => {
  if (indictateSecondLine && financeTypes.includes('secondary'))
    return `${apr / 100}% (2nd line)`;
  return `${apr / 100}% APR${suppressInterestLabel ? '' : ' interest'}`;
};

export const getFinanceRateFullInfoKey = (rate: FinanceRate): string =>
  [
    rate.provider,
    rate.apr,
    rate.duration,
    rate.fee,
    rate.charge,
    rate?.deferred_duration ? 'bnpl' : undefined,
  ]
    .filter((val) => val !== undefined)
    .join('-');

export const getFinanceRateFeeChargeProviderBnplKey = (
  rate: FinanceRate,
): string =>
  [
    rate.fee,
    rate.charge,
    rate.provider,
    rate?.deferred_duration ? 'bnpl' : undefined,
  ]
    .filter(Boolean)
    .join('-');

// DEV_NOTE: Below is temporary to show badge for coming soon. When etika
// is ready, remove this and uncomment function below this one
// export const filterForEnabledRates = (
//   rates: FinanceRate[],
//   hideComingSoon = false,
// ): FinanceRate[] => {
//   const initial = rates.filter((rate) => rate.enabled);
//   if (hideComingSoon) return initial;
//   const combined = [
//     ...initial,
//     ...COMING_SOON_1390_RATES,
//     ...(initial.some((rate: FinanceRate) => rate.apr === 1190)
//       ? []
//       : COMING_SOON_1190_RATES),
//   ];
//   return combined;
// };

export const filterForEnabledRates = (
  rates: FinanceRate[],
  hideComingSoon = true,
) => {
  const initial = rates.filter((rate) => rate.enabled);
  if (hideComingSoon) return initial;
  return initial;
};

export const filterForInterestFreeRates = (
  rates: FinanceRate[],
): FinanceRate[] => rates.filter((rate) => rate.apr_type === 'INTEREST_FREE');

export const filterForInterestBearingRates = (
  rates: FinanceRate[],
): FinanceRate[] =>
  rates.filter((rate) => rate.apr_type === 'INTEREST_BEARING');

export const filterForBNPLRates = (rates: FinanceRate[]): FinanceRate[] =>
  rates.filter((rate) => rate.apr_type === 'BUYNOW_PAYLATER');

export const filterForPrimaryRates = (rates: FinanceRate[]): FinanceRate[] =>
  rates.filter((rate) => rate.finance_types.includes('primary'));

export const filterForBuyNowPayLater = (
  rates: FinanceRate[],
  buyNowPayLater = false,
): FinanceRate[] =>
  rates.filter((rate) => {
    const isBuyNowPayLater = rateIsBuyNowPayLater(rate);

    return buyNowPayLater ? isBuyNowPayLater : !isBuyNowPayLater;
  });

export const filterForUnregulated = (
  rates: FinanceRate[],
  unregulated = false,
): FinanceRate[] =>
  rates.filter((rate) => {
    const isUnregulated = rateIsUnregulated(rate);
    return unregulated ? isUnregulated : !isUnregulated;
  });

export const filterForRatesWithJobTotal = (
  rates: FinanceRate[],
  jobTotal: number,
): FinanceRate[] =>
  rates.filter((rate) => {
    if (!rate.min_job_value && !rate.max_job_value) return true;

    return (
      jobTotal >= ((rate.min_job_value as number) || 0) &&
      jobTotal <= (rate.max_job_value as number)
    );
  });

export const filterForRatesWithDepositPercentage = (
  rates: FinanceRate[],
  depositPercentage: number,
): FinanceRate[] =>
  rates.filter((rate) => {
    if (!rate.min_deposit_pct && !rate.max_deposit_pct) return true;

    // Deposit percentage is stored as a pence value to avoid
    // issues with floating point numbers
    const formattedDepositPercentage = depositPercentage * 100;

    return (
      formattedDepositPercentage >= ((rate.min_deposit_pct as number) || 0) &&
      formattedDepositPercentage <= (rate.max_deposit_pct as number)
    );
  });

export const filterForRatesWithDepositAmount = (
  rates: FinanceRate[],
  depositAmount: number,
): FinanceRate[] =>
  rates.filter((rate) => {
    if (!rate.min_deposit_value && !rate.max_deposit_value) return true;

    return (
      depositAmount >= ((rate.min_deposit_value as number) || 0) &&
      depositAmount <= (rate.max_deposit_value as number)
    );
  });

export const filterForRatesByWorkType = (
  rates: FinanceRate[],
  workType?: Job['work_type'],
): FinanceRate[] =>
  rates.filter((rate) => {
    if ((rate?.work_types || []).length === 0) return true;
    return !workType || !rate.work_types || rate.work_types.includes(workType);
  });

export const getCompanyInitialRates = (rates: FinanceRate[]) =>
  pipe(
    rates,
    // Only return enabled rates
    filterForEnabledRates,
    // Only return rates that are available for primary finance
    filterForPrimaryRates,
  );

export const filterForNonSelectableRates = (
  rates: FinanceRate[],
): FinanceRate[] => rates.filter((rate) => !financeRateIsSelectable(rate));

export const filterForValidRates = (
  rates: FinanceRate[],
  workType?: Job['work_type'],
  unregulated = false,
  hideComingSoon = false,
  excludeSelectableRates = false,
): FinanceRate[] =>
  pipe(
    rates,
    // Only return enabled rates
    (currentRates) => filterForEnabledRates(currentRates, hideComingSoon),
    // Only return rates that are available for primary finance
    filterForPrimaryRates,
    (currentRates) => filterForRatesByWorkType(currentRates, workType),
    // Filter for rates depending on the buyNowPayLater flag
    (currentRates) => filterForUnregulated(currentRates, unregulated),
    // If excludeSelectableRates is true, then filter out selectable rates
    (currentRates) =>
      excludeSelectableRates
        ? filterForNonSelectableRates(currentRates)
        : currentRates,
  );

const sortInterestRateGroupByRateWithinBounds = (
  group: StringIndexedObject<FinanceRate[]>,
  total: number,
  depositPercentage: number,
  depositAmount: number,
): StringIndexedObject<FinanceRate[]> => {
  const keys = Object.keys(group);

  return keys.reduce(
    (rates, key) => ({
      ...rates,
      [key]: [...(group[key] || [])].sort((i, j) => {
        const iWithinBounds = financeRateIsWithinBounds(
          i,
          total,
          depositPercentage,
          depositAmount,
        )
          ? 1
          : 0;
        const jWithinBounds = financeRateIsWithinBounds(
          j,
          total,
          depositPercentage,
          depositAmount,
        )
          ? 1
          : 0;

        return jWithinBounds - iWithinBounds;
      }),
    }),
    {} as StringIndexedObject<FinanceRate[]>,
  );
};

export const filterForRequestableRates = (
  availableRates: FinanceRate[],
  companyRates: FinanceRate[],
) => {
  const availableEnabledRates = filterForEnabledRates(availableRates);
  const companyEnabledRates = filterForEnabledRates(companyRates);

  return availableEnabledRates.filter(
    (rate) =>
      !companyEnabledRates.some(
        (companyRate) => rate.name === companyRate.name,
      ),
  );
};

export const groupByInterestRate = (
  rates: FinanceRate[],
  indictateSecondLine = false,
  suppressInterestLabel = false,
): StringIndexedObject<FinanceRate[]> =>
  pipe(
    rates,
    // Group the rates by the group name (interest rate) & provider
    groupBy(
      flow(getFinanceRateDetails, (details) =>
        getFinanceRateGroupName(
          details,
          indictateSecondLine,
          suppressInterestLabel,
        ),
      ),
    ),
  );

export const groupValidRatesByInterestRate = (
  rates: FinanceRate[],
  total: number,
  depositPercentage: number,
  depositAmount: number,
  workType?: Job['work_type'],
  buyNowPayLater = false,
  hideComingSoon = false,
): StringIndexedObject<FinanceRate[]> =>
  pipe(
    rates,
    (currentRates) =>
      filterForValidRates(
        currentRates,
        workType,
        buyNowPayLater,
        hideComingSoon,
      ),
    // Group the rates by the group name (interest rate) & provider
    groupByInterestRate,
    (currentRates) =>
      sortInterestRateGroupByRateWithinBounds(
        currentRates,
        total,
        depositPercentage,
        depositAmount,
      ),
  );

const getMinimumDuration = (financeRates: FinanceRate[]): number =>
  [...(financeRates || [])]
    .map(({ duration }) => duration || 0)
    .sort()
    .shift() || 0;

// Sort the finance rates by the minimum duration
// so that each group is displayed in the correct order
const sortFinanceRateChargeAndProviderKeys = (
  group: StringIndexedObject<FinanceRate[]>,
): StringIndexedObject<FinanceRate[]> => {
  const keys = Object.keys(group);
  const sortedKeys = keys.sort((i, j) => {
    const minimumI = getMinimumDuration(group[i]);
    const minimumJ = getMinimumDuration(group[j]);

    return minimumI - minimumJ;
  });

  return sortedKeys.reduce(
    (rates, key) => ({
      ...rates,
      [key]: group[key],
    }),
    {} as StringIndexedObject<FinanceRate[]>,
  );
};

export const groupByFeeChargeProviderBnpl = (
  rates: FinanceRate[],
): StringIndexedObject<FinanceRate[]> =>
  pipe(
    rates,
    groupBy(getFinanceRateFeeChargeProviderBnplKey),
    sortFinanceRateChargeAndProviderKeys,
  );

export type CombinedRates = StringIndexedObject<
  StringIndexedObject<FinanceRate[]>
>;

export const getSortedFinanceRateKeys = (
  rates: StringIndexedObject<FinanceRate[]>,
): string[] =>
  Object.keys(rates).sort(
    (i, j) => Number(i.split('%').shift()) - Number(j.split('%').shift()),
  );

export const findCombinedRates = (
  rates: StringIndexedObject<FinanceRate[]>,
): CombinedRates =>
  getSortedFinanceRateKeys(rates).reduce(
    (formattedRates, rateKey) => ({
      ...formattedRates,
      [rateKey]: groupByFeeChargeProviderBnpl(rates[rateKey]),
    }),
    {} as CombinedRates,
  );

export const filterInfoWorkType = (
  workType: WorkType,
  infoWorkType: InfoWorkType[],
): InfoWorkType | undefined =>
  infoWorkType.filter((info: InfoWorkType) => info.work_type === workType)?.[0];

export const matchRates = (
  userRates: FinanceRate[],
  availableRates?: FinanceRate[],
): FinanceRate[] => {
  if (!availableRates) return userRates;
  return availableRates.reduce(
    (final: FinanceRate[], currentAvailable: FinanceRate) => {
      const matched = userRates.filter(
        (rate) =>
          rate.name === currentAvailable.name &&
          rate.provider === currentAvailable.provider &&
          rate.finance_types.every((type) =>
            currentAvailable.finance_types.includes(type),
          ),
      );
      if (matched.length !== 1) return final;
      return [...final, matched[0]];
    },
    [],
  );
};

export const getCurrentInfoWorkType = (
  companyRates: FinanceRate[] | undefined,
  infoWorkType: InfoWorkType[] | undefined,
  workType: WorkType | undefined,
): InfoWorkType | undefined => {
  if (!companyRates || !infoWorkType || !workType) return undefined;
  const filteredWorkType = filterInfoWorkType(workType, infoWorkType);
  if (!filteredWorkType) return undefined;
  const applicableRates = pipe(
    companyRates,
    filterForEnabledRates,
    filterForPrimaryRates,
  );
  const matchedRates = matchRates(applicableRates, filteredWorkType?.rates);
  return {
    ...filteredWorkType,
    rates: matchedRates,
  };
};

export const getRatesFromDurationApr = (
  aprDurationKey: string,
  rates: FinanceRate[],
): FinanceRate[] => {
  const split = aprDurationKey.split('-');
  const [apr, duration, aprType] = [
    parseInt(split[0], 10),
    parseInt(split[1], 10),
    split[2],
  ];
  return rates.filter(
    (rate) =>
      rate.apr === apr &&
      rate.duration === duration &&
      rate.apr_type === aprType,
  );
};

export const groupByKeys = (
  keys: string[],
  infoWorkType: InfoWorkType,
): Record<string, FinanceRate[]> =>
  keys.reduce(
    (final: Record<string, FinanceRate[]>, key: string) => ({
      ...final,
      [key]: getRatesFromDurationApr(key, infoWorkType.rates),
    }),
    {},
  );

export const getDurationAprKeys = (infoWorkType: InfoWorkType): string[] => {
  const { rates } = infoWorkType;
  return rates
    .map((rate: FinanceRate) => `${rate.apr}-${rate.duration}-${rate.apr_type}`)
    .filter(
      (key: string, index: number, keys: string[]) =>
        keys.indexOf(key) === index,
    );
};

export const groupByDurationAndApr = (
  infoWorkType: InfoWorkType,
): Record<string, FinanceRate[]> =>
  pipe(infoWorkType, getDurationAprKeys, (keys) =>
    groupByKeys(keys, infoWorkType),
  );

export const checkAboveMinDeposit = (
  deposit: number,
  depositPercentage: number,
  minDepositPercentage: number,
  minDepositValue: number,
): boolean => {
  if (!minDepositValue) return depositPercentage >= minDepositPercentage;
  return (
    depositPercentage >= minDepositPercentage && deposit >= minDepositValue
  );
};

export const checkBelowMaxDeposit = (
  deposit: number,
  depositPercentage: number,
  maxDepositPercentage: number,
  maxDepositValue: number,
): boolean => {
  if (!maxDepositValue) return depositPercentage <= maxDepositPercentage;
  return (
    depositPercentage <= maxDepositPercentage && deposit <= maxDepositValue
  );
};

export const checkRateValidByTotalAndDeposit = (
  rate: FinanceRate,
  total: number,
  setMinimumDeposit: number,
): boolean => {
  const {
    min_deposit_pct: minDepPct = 0,
    max_deposit_pct: maxDepPct = 0,
    min_deposit_value: minDepVal = 0,
    max_deposit_value: maxDepVal = 0,
    min_job_value: minLoan = 0,
    max_job_value: maxLoan = 0,
  } = rate;

  // Get set minimum deposit as %
  const setMinimumDepositPct = 10000 * (setMinimumDeposit / total);

  // Determine the minimum and maximum deposit values
  const minAvailableDeposit = Math.max(
    minDepVal || 0,
    (minDepPct / 10000) * total,
    setMinimumDeposit,
  );
  const maxAvailableDeposit = Math.min(
    maxDepVal || Infinity,
    (maxDepPct / 10000) * total,
  );

  // Detemine the minimum and maximum loan amounts
  const minAvailableLoanAmount = total - maxAvailableDeposit;
  const maxAvailableLoanAmount = total - minAvailableDeposit;

  // First check: Minimum deposit set by TP is above rate maximum
  if (maxDepPct && setMinimumDepositPct > maxDepPct) return false;
  if (maxDepVal && setMinimumDeposit > maxDepVal) return false;

  // Second check: is minimum loan above maximum rate loan and
  // is maximum loan amount below minimum rate loan
  if (maxLoan && minAvailableLoanAmount > maxLoan) return false;
  if (minLoan && maxAvailableLoanAmount < minLoan) return false;

  return true;
};

export const checkRateValidByTotal = (
  rate: FinanceRate,
  total: number,
  deposit: number,
): boolean => {
  const loan = total - deposit;
  const { min_job_value: minLoan = 0, max_job_value: maxLoan = 0 } = rate;
  const withinLoanBounds = maxLoan
    ? loan >= minLoan && loan <= maxLoan
    : loan >= minLoan;
  return withinLoanBounds;
};

export const checkRateValidByDeposit = (
  rate: FinanceRate,
  total: number,
  deposit: number,
): boolean => {
  const depositPct = 10000 * (deposit / total);
  const {
    min_deposit_pct: minDepPct = 0,
    max_deposit_pct: maxDepPct = 0,
    min_deposit_value: minDepVal = 0,
    max_deposit_value: maxDepVal = 0,
  } = rate;
  const aboveMinDep = checkAboveMinDeposit(
    deposit,
    depositPct,
    minDepPct,
    minDepVal,
  );
  const belowMaxDep = checkBelowMaxDeposit(
    deposit,
    depositPct,
    maxDepPct,
    maxDepVal,
  );
  return aboveMinDep && belowMaxDep;
};

export const filterRatesByTotalAndDeposit = (
  rates: FinanceRate[],
  total: number,
  deposit: number,
): FinanceRate[] =>
  rates.filter((rate) => checkRateValidByTotalAndDeposit(rate, total, deposit));

export const filterRatesPartialByTotalAndDeposit = (
  rates: FinanceRate[],
  total: number,
  deposit: number,
): FinanceRate[] =>
  rates.filter((rate) =>
    [
      checkRateValidByTotal(rate, total, deposit),
      checkRateValidByDeposit(rate, total, deposit),
    ].some((val) => val),
  );

export const checkRateTotalLimited = (
  rate: FinanceRate,
  total: number,
  deposit: number,
): boolean => {
  const loan = total - deposit;
  const { min_job_value: minLoan = 0, max_job_value: maxLoan = 0 } = rate;
  return loan >= minLoan && loan <= maxLoan;
};

export const checkRatesTotalLimited = (
  rates: FinanceRate[],
  total: number,
  deposit: number,
): boolean =>
  rates.filter((rate) => checkRateTotalLimited(rate, total, deposit)).length >
  0;

const FINANCE_PROVIDER_ORDER = ['allium', 'propensio', 'humm'];

export const getHighestLoanRate = (rates: FinanceRate[]): FinanceRate =>
  rates.sort(
    (rate1, rate2) =>
      (rate2.max_job_value || 0) - (rate1?.max_job_value || 0) ||
      FINANCE_PROVIDER_ORDER.indexOf(rate1.provider) -
        FINANCE_PROVIDER_ORDER.indexOf(rate2.provider),
  )[0];

export const getHighestDepositRate = (rates: FinanceRate[]): FinanceRate =>
  rates.sort((rate1: FinanceRate, rate2: FinanceRate) => {
    const {
      max_deposit_pct: maxDepPct1 = 0,
      max_deposit_value: maxDepVal1 = 0,
      max_job_value: maxLoan1 = 0,
    } = rate1;
    const max1 = maxDepVal1 || (maxLoan1 * maxDepPct1) / 10000;
    const {
      max_deposit_pct: maxDepPct2 = 0,
      max_deposit_value: maxDepVal2 = 0,
      max_job_value: maxLoan2 = 0,
    } = rate2;
    const max2 = maxDepVal2 || (maxLoan2 * maxDepPct2) / 10000;
    return max2 - max1;
  })[0];

export const getHighestBoundRate = (
  rates: FinanceRate[],
  total: number,
  deposit: number,
): FinanceRate => {
  const totalLimited = checkRatesTotalLimited(rates, total, deposit);
  if (totalLimited) return getHighestLoanRate(rates);
  return getHighestDepositRate(rates);
};

export const getBoundedRate = (
  rates: FinanceRate[],
  total: number,
  deposit: number,
  primary?: FinanceProvider,
): FinanceRate => {
  const primaryRate = primary
    ? rates.filter((rate) => rate.provider === primary)?.[0]
    : undefined;
  if (!primaryRate) {
    const filtered = filterRatesByTotalAndDeposit(rates, total, deposit);
    if (filtered.length === 0)
      return getHighestBoundRate(rates, total, deposit);
    const sorted = filtered.sort(
      (rate1: FinanceRate, rate2: FinanceRate) =>
        FINANCE_PROVIDER_ORDER.indexOf(rate1.provider) -
        FINANCE_PROVIDER_ORDER.indexOf(rate2.provider),
    );
    return sorted[0];
  }
  const primaryValid = checkRateValidByTotalAndDeposit(
    primaryRate,
    total,
    deposit,
  );
  if (primaryValid) return primaryRate;
  const filtered = filterRatesByTotalAndDeposit(rates, total, deposit);
  if (filtered.length === 0) return getHighestBoundRate(rates, total, deposit);
  const sorted = filtered.sort(
    (rate1: FinanceRate, rate2: FinanceRate) =>
      FINANCE_PROVIDER_ORDER.indexOf(rate1.provider) -
      FINANCE_PROVIDER_ORDER.indexOf(rate2.provider),
  );
  return sorted[0];
};

export const filterGroupedRates = (
  grouped: Record<string, FinanceRate[]>,
  total: number,
  deposit: number,
  primary?: FinanceProvider,
): FinanceRate[] =>
  Object.keys(grouped).reduce((rates: FinanceRate[], key: string) => {
    const currentRates = grouped[key];
    if (currentRates.length === 1) return [...rates, currentRates[0]];
    const rate = getBoundedRate(currentRates, total, deposit, primary);
    return [...rates, rate];
  }, []);

export const getValidFinanceRates = (
  infoWorkType: InfoWorkType | undefined,
  total: number,
  deposit: number,
): FinanceRate[] | undefined => {
  if (!infoWorkType) return undefined;
  return pipe(infoWorkType, groupByDurationAndApr, (grouped) =>
    filterGroupedRates(grouped, total, deposit, infoWorkType.primary),
  );
};

export const filterForUnregulatedRates = (
  rates: FinanceRate[],
): FinanceRate[] =>
  rates.filter(
    (rate: FinanceRate) =>
      (rate.duration || 99) < 12 && rate.apr_type === 'INTEREST_FREE',
  );

export const filterForRegulatedRates = (rates: FinanceRate[]): FinanceRate[] =>
  rates.filter(
    (rate: FinanceRate) =>
      rate.apr_type !== 'INTEREST_FREE' || (rate.duration || 0) >= 12,
  );

export type GroupedByKey = Record<string, FinanceRate[]>;

export type GroupedByAprAndFee = Record<string, GroupedByKey>;

export type GroupedByAprAndDuration = Record<string, GroupedByKey>;

export const getAprFromName = (rate: FinanceRate): string => {
  const { name } = rate;
  if (name.includes('INTEREST_FREE')) return '0';
  return name.split('_').slice(-1)[0];
};

export const groupByAprBNPL = (rates: FinanceRate[]): GroupedByKey =>
  rates.reduce((final: GroupedByKey, rate: FinanceRate) => {
    const apr =
      rate.apr || rate.apr === 0 ? String(rate.apr) : getAprFromName(rate);
    const bnpl = rate.name.includes('BUYNOW_PAYLATER');
    const key = bnpl ? 'bnpl' : String(apr);
    if (Object.keys(final).includes(key)) {
      return {
        ...final,
        [key]: [...final[key], rate],
      };
    }
    return {
      ...final,
      [key]: [rate],
    };
  }, {});

export const groupByApr = (rates: FinanceRate[]): GroupedByKey =>
  rates.reduce((final: GroupedByKey, rate: FinanceRate) => {
    const apr =
      rate.apr || rate.apr === 0 ? String(rate.apr) : getAprFromName(rate);
    if (Object.keys(final).includes(apr)) {
      return {
        ...final,
        [apr]: [...final[apr], rate],
      };
    }
    return {
      ...final,
      [apr]: [rate],
    };
  }, {});

export const groupByFee = (rates: FinanceRate[]): GroupedByKey =>
  rates.reduce((final: GroupedByKey, rate: FinanceRate) => {
    const fee = String(rate.fee);
    const bnpl = rate?.apr_type === 'BUYNOW_PAYLATER';
    const key = `${fee}${bnpl ? '-bnpl' : ''}`;
    if (Object.keys(final).includes(key)) {
      return {
        ...final,
        [key]: [...final[key], rate],
      };
    }
    return {
      ...final,
      [key]: [rate],
    };
  }, {});

export const groupGroupedByFee = (grouped: GroupedByKey): GroupedByAprAndFee =>
  Object.keys(grouped).reduce((final: GroupedByAprAndFee, apr: string) => {
    const current = grouped[apr];
    return {
      ...final,
      [apr]: groupByFee(current),
    };
  }, {});

export const groupByAprAndFee = (rates: FinanceRate[]): GroupedByAprAndFee =>
  pipe(rates, groupByApr, groupGroupedByFee);

export const rateValuesKey = (rate: FinanceRate): string => {
  const fee = String(rate.fee);
  const minDep = String(rate.min_deposit_value || 0);
  const minDepPct = String(rate.min_deposit_pct || 0);
  const maxDep = String(rate.max_deposit_value || 0);
  const maxDepPct = String(rate.max_deposit_pct || 0);
  const minVal = String(rate.min_job_value || 0);
  const maxVal = String(rate.max_job_value || 0);
  const bnpl = rate?.apr_type === 'BUYNOW_PAYLATER';
  return [
    fee,
    minDep,
    minDepPct,
    maxDep,
    maxDepPct,
    minVal,
    maxVal,
    bnpl ? 'bnpl' : undefined,
  ]
    .filter(Boolean)
    .join('-');
};

export const groupByValuesAndFee = (rates: FinanceRate[]): GroupedByKey =>
  rates.reduce((final: GroupedByKey, rate: FinanceRate) => {
    const key = rateValuesKey(rate);
    if (Object.keys(final).includes(key)) {
      return {
        ...final,
        [key]: [...final[key], rate],
      };
    }
    return {
      ...final,
      [key]: [rate],
    };
  }, {});

export const groupGroupedByValuesAndFee = (
  grouped: GroupedByKey,
): GroupedByAprAndFee =>
  Object.keys(grouped).reduce((final: GroupedByAprAndFee, apr: string) => {
    const current = grouped[apr];
    return {
      ...final,
      [apr]: groupByValuesAndFee(current),
    };
  }, {});

export const groupByAprValuesAndFee = (
  rates: FinanceRate[],
): GroupedByAprAndFee => pipe(rates, groupByApr, groupGroupedByValuesAndFee);

export const groupByDuration = (rates: FinanceRate[]): GroupedByKey =>
  rates.reduce((final: GroupedByKey, rate: FinanceRate) => {
    const key = String(rate.duration || 0);
    if (Object.keys(final).includes(key)) {
      return {
        ...final,
        [key]: [...final[key], rate],
      };
    }
    return {
      ...final,
      [key]: [rate],
    };
  }, {});

export const groupGroupedByDuration = (
  grouped: GroupedByKey,
): GroupedByAprAndDuration =>
  Object.keys(grouped).reduce((final: GroupedByAprAndDuration, apr: string) => {
    const current = grouped[apr];
    return {
      ...final,
      [apr]: groupByDuration(current),
    };
  }, {});

export const groupByAprAndDuration = (
  rates: FinanceRate[],
  separateBNPL = true,
): GroupedByAprAndDuration => {
  if (!separateBNPL) return pipe(rates, groupByApr, groupGroupedByDuration);
  return pipe(rates, groupByAprBNPL, groupGroupedByDuration);
};

export const isGroupedRates = (rates: FinanceRate[]): boolean =>
  rates.length > 1;

export const getDuration = (rate: FinanceRate): string =>
  String(rate.duration || 0);

export const getMinMax = (rates: FinanceRate[]): number[] => {
  const durations = rates
    .map((rate) => getDuration(rate))
    .sort((d1, d2) => parseInt(d1, 10) - parseInt(d2, 10));
  return [parseInt(durations[0], 10), parseInt(durations.slice(-1)[0], 10)];
};

export type Timestep = 'm' | 'y';

export const getTimestep = (duration: number): Timestep =>
  duration % 12 === 0 ? 'y' : 'm';

export const timestepToVerbose = (timestep: Timestep): string =>
  timestep === 'm' ? 'months' : 'years';

export const getMonthsLabel = (months: number, long = true): string =>
  `${months} ${long ? 'month' : 'mo'}${months > 1 && long ? 's' : ''}`;

export const getYearsLabel = (months: number, long = true): string =>
  `${months / 12} ${long ? 'year' : 'yr'}${months > 12 && long ? 's' : ''}`;

export const prependBnplLabel = (
  label: string,
  rates: FinanceRate[],
): string => {
  const bnpl = rates.every((rate) => rate.apr_type === 'BUYNOW_PAYLATER');
  if (!bnpl) return label;
  return `BNPL ${label}`;
};

export const getGroupedDurationLabel = (rates: FinanceRate[]): string => {
  const [min, max] = getMinMax(rates);
  const minTimeStep = getTimestep(min);
  const maxTimeStep = getTimestep(max);
  if (minTimeStep === maxTimeStep) {
    if (minTimeStep === 'm') return `${min}-${max} months`;
    return `${min / 12}-${max / 12} years`;
  }
  return `${
    minTimeStep === 'm' ? getMonthsLabel(min, false) : getYearsLabel(min, false)
  } - ${
    maxTimeStep === 'm' ? getMonthsLabel(max, false) : getYearsLabel(max, false)
  }`;
};

export const getGroupedLabel = (rates: FinanceRate[]): string =>
  pipe(rates, getGroupedDurationLabel, (label) =>
    prependBnplLabel(label, rates),
  );

export const getLabelFromDuration = (duration: number): string => {
  const timestep = getTimestep(duration);
  return timestep === 'm' ? getMonthsLabel(duration) : getYearsLabel(duration);
};

export const getSingleLabel = (rate: FinanceRate): string =>
  pipe(
    rate,
    getDuration,
    (duration) => parseInt(duration, 10),
    getLabelFromDuration,
    (label) => prependBnplLabel(label, [rate]),
  );

export const getRatesLabel = (rates: FinanceRate[], grouped = false): string =>
  grouped ? getGroupedLabel(rates) : getSingleLabel(rates[0]);

export const getFinanceRatesLabel = (rates: FinanceRate[]): string =>
  pipe(rates, isGroupedRates, (grouped) => getRatesLabel(rates, grouped));

export const getSelectedNameFromRate = (rate: FinanceRate): string => {
  const { provider, name } = rate;
  return `${provider}-${name}-${rateValuesKey(rate)}`;
};

export const getSelectedFinanceRates = (
  rates: FinanceRate[] | undefined,
  selected: string[] | undefined,
): FinanceRate[] | undefined => {
  if (!rates || !selected || selected.length === 0) return undefined;
  return rates.filter((rate: FinanceRate) =>
    selected.includes(getSelectedNameFromRate(rate)),
  );
};

export const filterRatesBySelectedKeys = (
  rates: FinanceRate[],
  selectedFinanceOptions: string[] | undefined,
): FinanceRate[] => {
  if (!selectedFinanceOptions || selectedFinanceOptions.length === 0) return [];
  return rates.filter((rate: FinanceRate) =>
    selectedFinanceOptions.includes(getSelectedNameFromRate(rate)),
  );
};

export const getStoredFinanceRates = (
  rates: FinanceRate[] | undefined,
  selectedFinanceOptions: string[] | undefined,
  total: number,
  deposit: number,
): FinanceRate[] | undefined => {
  if (!rates) return undefined;
  const regulatedFiltered = pipe(rates, filterForRegulatedRates, (reg) =>
    filterRatesByTotalAndDeposit(reg, total, deposit),
  );
  const unegulatedFiltered = pipe(
    rates,
    filterForUnregulatedRates,
    (unreg) => filterRatesByTotalAndDeposit(unreg, total, deposit),
    (filtered) => filterRatesBySelectedKeys(filtered, selectedFinanceOptions),
  );
  return [...unegulatedFiltered, ...regulatedFiltered];
};

export const calculateMonthlyRepaymentRaw = (
  loan: number,
  apr: number,
  terms: number,
): number => {
  if (apr === 0) return loan / terms;
  const rate = apr / 12 / 10000;
  const compoundInterest = (1 + rate) ** terms;
  return (loan * (rate * compoundInterest)) / (compoundInterest - 1);
};

export const getMonthlyCost = (
  rate: FinanceRate,
  total: number,
  deposit: number,
): number => {
  const loan = total - deposit;
  const { duration = 0, apr = 0 } = rate;
  return pipe(calculateMonthlyRepaymentRaw(loan, apr, duration), Math.ceil);
};

export const sortRatesByTerm = (
  rates: FinanceRate[],
  longestFirst = true,
): FinanceRate[] =>
  rates.sort((r1: FinanceRate, r2: FinanceRate) => {
    const d1 = r1.duration || 0;
    const d2 = r2.duration || 0;
    return longestFirst ? d2 - d1 : d1 - d2;
  });

export const getLongestTerm = (rates: FinanceRate[]): FinanceRate =>
  rates.length === 1 ? rates[0] : sortRatesByTerm(rates)[0];

export const getLowestCostToConsumer = (
  rates: FinanceRate[],
  total: number,
  deposit: number,
): number =>
  pipe(rates, getLongestTerm, (rate) => getMonthlyCost(rate, total, deposit));

export const getRateCost = (
  rate: FinanceRate,
  total: number,
  deposit: number,
): number => {
  const loan = total - deposit;
  const { fee, charge } = rate;
  return loan * (fee / 10000) + charge;
};

export const getCostToTrade = (
  rates: FinanceRate[],
  total: number,
  deposit: number,
): number =>
  pipe(rates, getLongestTerm, (rate) => getRateCost(rate, total, deposit));

export const getRateSubsidy = (
  rate: FinanceRate,
  total: number,
  deposit: number,
): number => {
  const { fee } = rate;
  const loan = total - deposit;
  return Math.ceil((loan * fee) / 10000);
};

type WorkTypeAndRates = {
  workType: WorkType | undefined;
  rates: FinanceRate[] | undefined;
};

export const getWorkTypeAndRates = (job: Job): WorkTypeAndRates => ({
  workType: job?.work_type,
  rates: job?.finance_options,
});

export const getJobRates = (
  job: Job,
  infoWorkType: InfoWorkType[],
): FinanceRate[] | undefined => {
  const { totalIncVat: total } = calculateJobTotal(job.job_items);
  const deposit = job?.deposit_value?.amount;
  return pipe(
    job,
    getWorkTypeAndRates,
    ({ workType, rates }) =>
      getCurrentInfoWorkType(rates, infoWorkType, workType),
    (workType) => getValidFinanceRates(workType, total, deposit),
  );
};

export const sortByAprAndDuration = (
  rates: FinanceRate[],
  lowestAprFirst = true,
  lowestDurationFirst = true,
): FinanceRate[] =>
  rates.sort((r1: FinanceRate, r2: FinanceRate) => {
    const { apr: apr1 = 0, duration: duration1 = 0 } = r1;
    const { apr: apr2 = 0, duration: duration2 = 0 } = r2;
    if (apr1 === apr2)
      return lowestDurationFirst
        ? duration1 - duration2
        : duration2 - duration1;
    return lowestAprFirst ? apr1 - apr2 : apr2 - apr1;
  });

export const formatAprString = (apr: string): string => {
  if (apr === '0') return apr;
  const len = apr.length;
  const parts = [apr.substring(0, len - 2), apr.substring(len - 2)];
  if (parts[1] === '00') return parts[0];
  if (parts[1][1] === '0') return `${parts[0]}.${parts[1][0]}`;
  return `${parts[0]}.${parts[1]}`;
};

export const filterOut149If129 = (
  rates: FinanceRate[],
  total: number,
  deposit: number,
): FinanceRate[] => {
  const rates129 = rates.filter((rate) => rate.apr === 1290);
  if (rates129.length === 0) return rates;
  const validRates129 = rates129.filter((rate) =>
    checkRateValidByTotalAndDeposit(rate, total, deposit),
  );
  if (validRates129.length === 0) return rates;
  return rates.filter(
    (rate) =>
      !((rate.apr || 0) === 1490 && rate.apr_type === 'INTEREST_BEARING'),
  );
};

export const filterRatesByProvider = (
  rates: FinanceRate[],
  provider: FinanceProvider,
): FinanceRate[] => rates.filter((rate) => rate.provider === provider);

export const hasMatchingRateInArray = (
  rates: FinanceRate[],
  rate: FinanceRate,
): boolean =>
  rates.some(
    (current) =>
      getFinanceRateFullInfoKey(current) === getFinanceRateFullInfoKey(rate),
  );

export const filerOutHummRegulatedIfOtherRegulated = (
  rates: FinanceRate[],
  total: number,
  deposit: number,
): FinanceRate[] => {
  const regulatedRates = filterForRegulatedRates(rates);
  const validRegulatedRates = regulatedRates.filter((rate) =>
    checkRateValidByTotalAndDeposit(rate, total, deposit),
  );
  const hummRates = filterRatesByProvider(validRegulatedRates, 'humm');
  const validHummRates = hummRates.filter((rate) =>
    checkRateValidByTotalAndDeposit(rate, total, deposit),
  );
  if (validHummRates.length === validRegulatedRates.length) return rates;
  return regulatedRates.filter(
    (rate) => !hasMatchingRateInArray(hummRates, rate),
  );
};

export const filterOutPropensio149IfAllium149 = (
  rates: FinanceRate[],
  total: number,
  deposit: number,
): FinanceRate[] => {
  const allium149 = rates
    .filter((rate) => rate.provider === 'allium' && rate.apr === 1490)
    .filter((rate) => checkRateValidByTotalAndDeposit(rate, total, deposit));
  if (allium149.length === 0) return rates;
  return rates.filter((rate) => {
    if (rate.provider !== 'propensio') return true;
    if (rate.apr_type !== 'INTEREST_BEARING') return true;
    return rate.apr !== 1490;
  });
};

export const getFinanceRateNameKey = (rate: FinanceRate): string =>
  [rate.provider, rate.name].join('-');

export const mapCompanyRatesToAvailableRates = (
  availableRates: FinanceRate[],
  companyRates: FinanceRate[],
): FinanceRate[] =>
  availableRates.reduce((final: FinanceRate[], current: FinanceRate) => {
    const currentKey = getFinanceRateNameKey(current);
    const companyRateMatch = companyRates.filter(
      (rate: FinanceRate) => getFinanceRateNameKey(rate) === currentKey,
    );
    if (companyRateMatch.length !== 1) return [...final, current];
    return [...final, companyRateMatch[0]];
  }, []);

export const getAllRateKeyNames = (rates: FinanceRate[]): string[] =>
  rates.map(getFinanceRateNameKey);

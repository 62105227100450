import { Text } from '@kanda-libs/ks-design-library';
import FinanceEverywhereVideoEmbed from 'components/FinanceEverywhereVideoEmbed';
import MyAccountFinanceEverywhereLinkTags from './Tags';

const MyAccountFinanceEverywhereLinkContent: React.FunctionComponent =
  function () {
    return (
      <div className="flex flex-col">
        <Text
          text="Finance Everywhere Link"
          className="text-16-20-em text-neutral-900 mb-2"
        />
        <Text
          text="Set up and manage your Finance Everywhere Links. You can have more than one to allow for tracking finance applications and leads coming from different marketing locations"
          className="text-14-22 text-neutral-700 mb-6"
        />
        <MyAccountFinanceEverywhereLinkTags />
        <Text
          text="How Finance Everywhere Links work"
          className="text-16-20-em text-neutral-900 mb-2 mt-4"
        />
        <FinanceEverywhereVideoEmbed />
      </div>
    );
  };

export default MyAccountFinanceEverywhereLinkContent;

import { Document } from '@kanda-libs/ks-frontend-services';
import { useCallback, useMemo } from 'react';
import { base64toBlob } from 'utils';

export interface FinalFileCardHook {
  name: string;
  onClick: () => void;
}

export default function useFinalFileCard(
  document: Document | undefined,
): FinalFileCardHook {
  const name = useMemo(() => {
    if (!document) return 'No filename';
    return document.name;
  }, [document]);

  const onClick = useCallback(() => {
    if (!document) return;
    const { mimetype = 'application/pdf', content } = document;
    if (!content) return;
    const blob = base64toBlob(content, mimetype);
    const w = window.open('about:blank');
    if (!w) return;
    w.location = URL.createObjectURL(blob);
  }, [document]);

  return {
    name,
    onClick,
  };
}

import {
  actions,
  type AuthUser,
  type Company,
  type Tag,
} from '@kanda-libs/ks-frontend-services';
import { useAppDispatch } from 'components/App';
import { useCurrentCompany, useMe } from 'hooks';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  type FunctionComponent,
  type ReactNode,
} from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store';
import { LOGO_B64 } from './constants';

export interface MyAccountFinanceEverywhereLinkContextType {
  tags: Tag[];
  company: Company | undefined;
  me: AuthUser | undefined;
  cid: string | undefined;
  logoBase64: string | undefined;
  isLoading: boolean;
  isSubmitting: boolean;
}

export const MyAccountFinanceEverywhereLinkContext =
  createContext<MyAccountFinanceEverywhereLinkContextType>({
    tags: [],
    company: undefined,
    me: undefined,
    cid: undefined,
    logoBase64: undefined,
    isLoading: false,
    isSubmitting: false,
  });

export const useMyAccountFinanceEverywhereLinkContext = () =>
  useContext(MyAccountFinanceEverywhereLinkContext);

export interface MyAccountFinanceEverywhereLinkProviderProps {
  children: ReactNode;
}

const MyAccountFinanceEverywhereLinkProvider: FunctionComponent<MyAccountFinanceEverywhereLinkProviderProps> =
  function ({ children }) {
    const { company, isLoading: companyIsLoading } = useCurrentCompany();
    const { me } = useMe();
    const [logoBase64, setLogoBase64] = useState<string | undefined>(undefined);

    const tags = useSelector(selectors.tag.getEntitiesAsArray);
    const tagsIsLoading = useSelector(selectors.tag.getIsLoading);
    const isSubmitting = useSelector(selectors.tag.getIsSubmitting);
    const dispatch = useAppDispatch();

    const isLoading = useMemo(
      () => tagsIsLoading || companyIsLoading,
      [tagsIsLoading, companyIsLoading],
    );

    const cid = useMemo(() => company?.cid, [company]);

    const values = useMemo(
      () => ({
        tags,
        company: company || undefined,
        me,
        cid,
        logoBase64,
        isLoading,
        isSubmitting,
      }),
      [tags, company, me, cid, logoBase64, isLoading, isSubmitting],
    );

    const fetchRef = useRef<boolean>(false);
    useEffect(() => {
      if (fetchRef.current) return;
      if (!company) return;
      const logoId = company?.company_logo?.id;
      fetchRef.current = true;
      if (!logoId) {
        setLogoBase64(LOGO_B64);
        return;
      }
      dispatch(
        actions.getDocument({
          params: { id: logoId },
          onSuccess: (data) => {
            setLogoBase64(
              `data:${data.mimetype || 'image/png'};base64,${data.content}`,
            );
          },
        }),
      );
    }, [company, dispatch]);

    return (
      <MyAccountFinanceEverywhereLinkContext.Provider value={values}>
        {children}
      </MyAccountFinanceEverywhereLinkContext.Provider>
    );
  };

export default MyAccountFinanceEverywhereLinkProvider;

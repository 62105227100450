import { Icon } from '@kanda-libs/ks-design-library';
import type { TagRenderSize } from '../../TagRender';
import TagRender from '../../TagRender';
import useTagDownloadButton from './useTagDownloadButton';

export interface TagDownloadButtonProps {
  url: string;
  logoBase64: string;
  logoSize: number[];
  id: string;
  scale?: TagRenderSize;
}

const TagDownloadButton: React.FunctionComponent<TagDownloadButtonProps> =
  function ({ url, logoBase64, logoSize, id, scale = 'S' }) {
    const { size, width, height, onClick, ref } = useTagDownloadButton(
      scale,
      id,
    );
    return (
      <button
        onClick={onClick}
        type="button"
        aria-label={`tag-download-${size}`}
        className="w-full flex px-4 py-3 border border-neutral-300 bg-neutral-000 hover:bg-green-100 hover:border-green-500 rounded-lg shadow-card"
      >
        <Icon icon="file" size={46} stroke="green-500" className="mr-3" />
        <div className="flex flex-col text-left">
          <p className="text-14-22-em md:text-16-24-em">{size}</p>
          <p className="text-12-18 md:text-14-22">
            Dimensions: {width}px (w) x {height}px (h)
          </p>
        </div>
        <div className="w-0 h-0 overflow-hidden">
          <TagRender
            url={url}
            logoBase64={logoBase64}
            logoSize={logoSize}
            scale={scale}
            elementRef={ref}
          />
        </div>
      </button>
    );
  };

export default TagDownloadButton;
